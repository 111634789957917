import React from 'react';
import PropTypes from 'prop-types';
import randomColor from 'randomcolor';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import Button from '../Button';

const TagList = props => {
  const { width, height, tags, tagsColor, ...otherProps } = props;
  const displayTags = tags.map(tag => (
    <Button
      key={nanoid()}
      tag
      onClick={tag.action}
      backgroundColor={tagsColor || randomColor()}
    >
      {tag.tagName}
    </Button>
  ));
  return (
    <div
      style={{ width, height }}
      {...otherProps}
      className={classNames({ 'tags-list': height })}
    >
      {displayTags}
    </div>
  );
};

TagList.propTypes = {
  /** Set container width */
  width: PropTypes.string,

  /** Set container height (this will automatically hide overflow and make it scrollable) */
  height: PropTypes.string,

  /** Set tags to display */
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      tagName: PropTypes.string.isRequired,
      action: PropTypes.func
    })
  ),

  /** Defined a unique color for all tags */
  tagsColor: PropTypes.string
};

TagList.defaultProps = {
  width: undefined,
  height: undefined,
  tags: [],
  tagsColor: undefined
};

export default TagList;
