import React, { useState, useEffect } from 'react';
import { TAB_SIZE } from 'containers/App/constants';
// import { Layout } from 'antd';
import { Dashboard } from 'containers';

// const { Content } = Layout;

const AppContainer = () => {
  const [collapse, setCollapse] = useState(window.innerWidth >= TAB_SIZE);
  useEffect(() => {
    const layoutWidth = () => {
      const shouldCollapse = window.innerWidth >= TAB_SIZE;
      if (collapse !== shouldCollapse) setCollapse(!collapse);
    };
    window.addEventListener('resize', layoutWidth);
    return () => {
      window.removeEventListener('resize', layoutWidth);
    };
  }, [collapse]);

  return <Dashboard />;
};

export default AppContainer;
