import createSelector from 'utils/createSelector';

import { initialState } from './reducer';

const selectLanguage = state => state.language || initialState;

const localeSelector = createSelector(
  selectLanguage,
  languageState => languageState.locale
);

export { selectLanguage, localeSelector };
