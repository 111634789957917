import { defineMessages } from 'react-intl';

export const scope = 'nosocialprofile';

export default defineMessages({
  messageText: {
    id: `${scope}.messageText`,
    defaultMessage:
      'Social is fun and all. But, you need to activate your social profile first.'
  },
  activateProfile: {
    id: `${scope}.activateProfile`,
    defaultMessage: 'Activate profile'
  }
});
