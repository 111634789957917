import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';

import Button from '../Button';

const ListingCard = props => {
  const {
    url,
    alt,
    title,
    description,
    onClickView,
    onClickMessage,
    onClickOptions
  } = props;
  return (
    <div className="list-card-container" style={{ position: 'relative' }}>
      <div className="list-card-thumbnail">
        <img src={url} alt={alt} />
      </div>
      <div className="list-card-core-container">
        <div className="list-card-text-container">
          <div className="list-card-title">{title}</div>
          <div className="list-card-description">{description}</div>
        </div>
        <div className="list-card-button-container">
          <Button
            className="list-card-button"
            data-testid="button-view"
            onClick={onClickView}
          >
            View
          </Button>
          <Button
            className="list-card-button"
            data-testid="button-message"
            onClick={onClickMessage}
          >
            Message
          </Button>
        </div>
      </div>
      <div className="icon-list-card" data-testid="icon-options">
        <FontAwesomeIcon icon="ellipsis-v" onClick={onClickOptions} />
      </div>
    </div>
  );
};

ListingCard.propTypes = {
  /** url of the thumbnail */
  url: PropTypes.string,
  /** alt of the thumbnail */
  alt: PropTypes.string,
  /** title of the card */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** description of the thumbnail */
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** onClick function of the view button */
  onClickView: PropTypes.func,
  /** onClick function of the message button */
  onClickMessage: PropTypes.func,
  /** onClick function of the options icon */
  onClickOptions: PropTypes.func
};
ListingCard.defaultProps = {
  url: '',
  alt: '',
  title: '',
  description: '',
  onClickView: undefined,
  onClickMessage: undefined,
  onClickOptions: undefined
};

export default ListingCard;
