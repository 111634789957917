import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import appMessages from 'containers/App/messages';
import { selectFetch } from 'containers/App/selectors';
import { Title } from 'components';
import dateFormats from 'assets/data/dateFormats.json';
import timeZones from 'assets/data/timezones.json';
import { FormInput, FormSelect, Button } from '@njano';
import dayjs from 'utils/dayjs';

import { createCoworkingAction } from '../actions';
import messages from '../messages';

const CreateCoworking = ({ destroyModal }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector(selectFetch);
  return (
    <Formik
      initialValues={{}}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...messages.name
              })
            }
          )
        ),
        fqdn: Yup.string()
          .matches(
            /^(?:(?:\*|(?!-)(?:xn--)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1})\.)(?:(?!-)(?:xn--)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.)*(?!-)(?:xn--)?(?:[a-zA-Z0-9]{1,50}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,})$/i,
            'please enter a valid url'
          )
          .required(
            intl.formatMessage(
              {
                ...appMessages.required
              },
              {
                attribute: intl.formatMessage({
                  ...messages.fqdn
                })
              }
            )
          ),
        email_manager: Yup.string()
          .required(
            intl.formatMessage(
              { ...appMessages.required },
              { attribute: intl.formatMessage(messages.email_manager) }
            )
          )
          .email(
            <FormattedMessage
              {...appMessages.email}
              values={{ attribute: intl.formatMessage(messages.email_manager) }}
            />
          ),
        name_manager: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...messages.name_manager
              })
            }
          )
        ),
        surname_manager: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...messages.surname_manager
              })
            }
          )
        ),
        date_format: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...appMessages.date_format
              })
            }
          )
        ),
        timezone: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...messages.timezone
              })
            }
          )
        )
      })}
      onSubmit={(values, actions) => {
        dispatch(
          createCoworkingAction(
            { ...values, fqdn: values?.fqdn?.toLowerCase() },
            destroyModal,
            actions
          )
        );
      }}
    >
      {() => (
        <Form className="tickets-addmodal">
          <Title name={intl.formatMessage(messages.newCoworking)} />
          <h1>
            <FormattedMessage {...messages.newCoworking} />
          </h1>
          <div className="nj-finance-card-base nj-py-3 nj-px-3 nj-mb-4">
            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.fqdn} />
            </div>
            <Field
              name="fqdn"
              type="text"
              placeholder="spacename.njano.org"
              component={FormInput}
            />
            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.type} />
            </div>
            <Field
              name="type"
              mode="multiple"
              selectOptions={[
                {
                  label: intl.formatMessage(messages.coworking),
                  value: 'coworking'
                },
                {
                  label: intl.formatMessage(messages.incubator),
                  value: 'incubator'
                }
              ]}
              notFoundContent={<FormattedMessage {...messages.notfound} />}
              component={FormSelect}
            />
            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.email_manager} />
            </div>
            <Field
              name="email_manager"
              type="text"
              placeholder={intl.formatMessage({
                ...messages.email_manager
              })}
              component={FormInput}
            />

            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.name_manager} />
            </div>
            <Field
              name="name_manager"
              type="text"
              placeholder={intl.formatMessage({
                ...messages.name_manager
              })}
              component={FormInput}
            />

            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.surname_manager} />
            </div>
            <Field
              name="surname_manager"
              type="text"
              placeholder={intl.formatMessage({
                ...messages.surname_manager
              })}
              component={FormInput}
            />

            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.name} />
            </div>
            <Field
              name="name"
              type="text"
              placeholder={intl.formatMessage({
                ...messages.name
              })}
              component={FormInput}
            />

            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.date_format} />
            </div>
            <Field
              name="date_format"
              placeholder={intl.formatMessage({
                ...messages.dateFormat
              })}
              notFoundContent={<FormattedMessage {...appMessages.notfound} />}
              showSearch
              optionFilterProp="children"
              selectOptions={dateFormats.map(format => {
                return {
                  label: dayjs('2019-03-14').format(format),
                  value: format
                };
              })}
              component={FormSelect}
            />

            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.timezone} />
            </div>
            <Field
              name="timezone"
              optionFilterProp="children"
              placeholder={intl.formatMessage({
                ...messages.timezone
              })}
              notFoundContent={<FormattedMessage {...appMessages.notfound} />}
              showSearch
              selectOptions={timeZones.map(tz => {
                return {
                  label: tz,
                  value: tz
                };
              })}
              component={FormSelect}
            />

            <div className="nj-flex-row nj-justify-content-end">
              <Button
                className="nj-mb-0"
                onClick={() => {
                  destroyModal();
                }}
              >
                <FormattedMessage {...appMessages.cancel} />
              </Button>
              <Button
                className="nj-mb-0"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                <FormattedMessage {...messages.create} />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CreateCoworking;
