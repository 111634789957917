import './css/noir-pro-font.css';
import './css/App.css';
import '../../node_modules/antd/dist/antd.css';

import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faPlusCircle,
  faQuestionCircle,
  faUserTie,
  faSearch,
  faSlidersH,
  faLock,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons';
import {
  faHeart as faRegHeart,
  faCommentDots as faRegCommentDots
} from '@fortawesome/free-regular-svg-icons';
import {
  faFacebookSquare,
  faYoutubeSquare,
  faInstagramSquare,
  faTwitterSquare,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';

library.add(
  faPlusCircle,
  faQuestionCircle,
  faUserTie,
  faSearch,
  faSlidersH,
  faLock,
  faEnvelope,
  faFacebookSquare,
  faYoutubeSquare,
  faInstagramSquare,
  faRegCommentDots,
  faTwitterSquare,
  faLinkedin,
  faRegHeart
);
