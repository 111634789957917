import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { ConfigProvider, Empty } from 'antd';

import { antdLocales } from '../../i18n';
import { localeSelector } from './selectors';

const LanguageProvider = props => {
  const locale = useSelector(localeSelector);
  const { messages, children } = props;

  return (
    <ConfigProvider locale={antdLocales[locale]} renderEmpty={Empty}>
      <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
        {React.Children.only(children)}
      </IntlProvider>
    </ConfigProvider>
  );
};

LanguageProvider.propTypes = {
  messages: PropTypes.objectOf(PropTypes.object),
  children: PropTypes.element.isRequired
};
LanguageProvider.defaultProps = {
  messages: {}
};
export default LanguageProvider;
