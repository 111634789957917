import createSelector from 'utils/createSelector';

import { initialState } from './reducer';

const selectGlobal = state => state.global || initialState;

export const selectGlobalInfo = createSelector(
  selectGlobal,
  globalState => globalState
);

export const selectCurrentUser = createSelector(
  selectGlobalInfo,
  ({ user: { token, expiresAt, ...userInfo } }) => userInfo
);

export const selectOnBoardingStatus = createSelector(
  selectCurrentUser,
  ({ onboarding_status: onboardingStatus }) => onboardingStatus
);
export const selectSidebarStatus = createSelector(
  selectGlobalInfo,
  ({ drawerStatus, sidebarStatus }) => ({ drawerStatus, sidebarStatus })
);

export const selectUserProfile = createSelector(
  selectCurrentUser,
  ({
    profile_picture: profilePicture,
    username,
    name,
    surname,
    full_name: fullName
  }) => ({
    profilePicture,
    username,
    name,
    surname,
    fullName
  })
);

export const selectUserPermission = createSelector(
  selectCurrentUser,
  ({ role, permissions }) => ({ role, permissions })
);

export const selectCurrentCoworking = createSelector(
  selectGlobalInfo,
  globalState => globalState.coworking
);

export const selectFetch = createSelector(
  selectGlobalInfo,
  ({ loading }) => loading
);

export const selectError = createSelector(
  selectGlobalInfo,
  ({ error, message }) => ({ error, message })
);

export const selectToken = createSelector(
  selectGlobalInfo,
  ({ user }) => user.token
);

export const selectExpiry = createSelector(
  selectGlobalInfo,
  ({ user }) => user.expiresAt
);
