import { PURGE } from 'redux-persist';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SET_AUTH,
  TRIGGER_SIDEBAR,
  TRIGGER_DRAWER,
  SET_INFO,
  USER_REQUEST,
  SET_USER,
  INFO_REQUEST,
  LOGOUT_REQUEST,
  TOKEN_EXPIRED
} from './constants';
export const fetchStart = () => {
  return {
    type: FETCH_START
  };
};

export const fetchSuccess = () => {
  return {
    type: FETCH_SUCCESS
  };
};

export const fetchError = error => {
  return {
    type: FETCH_ERROR,
    payload: error
  };
};

export const showMessage = message => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const setAuth = ({ token, expiresIn }) => {
  return {
    type: SET_AUTH,
    payload: { token, expiresIn }
  };
};

export const triggerSidebar = () => {
  return {
    type: TRIGGER_SIDEBAR
  };
};

export const triggerDrawer = () => {
  return {
    type: TRIGGER_DRAWER
  };
};

export const userRequest = () => {
  return {
    type: USER_REQUEST
  };
};

export const setInfo = info => {
  return {
    type: SET_INFO,
    payload: info
  };
};

export const setUser = user => {
  return {
    type: SET_USER,
    payload: user
  };
};

export const unsetUser = () => {
  return {
    type: PURGE,
    key: 'root',
    result: () => {}
  };
};

export const infoRequest = () => {
  return {
    type: INFO_REQUEST
  };
};

export const logoutRequest = () => {
  return {
    type: LOGOUT_REQUEST
  };
};

export const tokenExpired = () => {
  return {
    type: TOKEN_EXPIRED
  };
};
