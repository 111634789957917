import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { Router, Route, Switch } from 'react-router-dom';
import App from 'containers/App';
import history from 'utils/history';
import * as Sentry from '@sentry/browser';
import 'assets/style';
import 'assets/less/njano.less';
import { Preloader } from '@njano';

import { translationMessages } from './i18n';
import configureStore from './configureStore';

export { ConfigConsumer } from 'antd/es/config-provider';
const { NODE_ENV: env, REACT_APP_SENTRY_DSN: dsn } = process.env;
const MOUNT_NODE = document.getElementById('root');
const store = configureStore({}, history);
const persistor = persistStore(store);

if (dsn && env === 'production') {
  Sentry.init({
    dsn
  });
}
export const MainApp = ({ messages }) => (
  <Provider store={store}>
    <PersistGate loading={<Preloader />} persistor={persistor}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={props => <App messages={messages} {...props} />}
          />
        </Switch>
      </Router>
    </PersistGate>
  </Provider>
);

const render = messages => {
  ReactDOM.render(<MainApp messages={messages} />, MOUNT_NODE);
};

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl' /* webpackChunkName: "intl" */));
  })
    .then(() =>
      Promise.all([
        import(
          'intl/locale-data/jsonp/fr.js' /* webpackChunkName: "intl-locale-fr" */
        ),
        import(
          'intl/locale-data/jsonp/en.js' /* webpackChunkName: "intl-locale-en" */
        )
      ])
    )
    .then(() => render(translationMessages))
    .catch(err => {
      throw err;
    });
} else {
  render(translationMessages);
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  module.hot.accept(['./i18n', './containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    render(translationMessages);
  });
}
