import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Button from '../Button';

const sizeValidation = (file, maxSize) => {
  const isLtMS = file.size / 1024 / 1024 < maxSize;
  if (!isLtMS) {
    message.error(`File must smaller than ${maxSize}MB!`);
  }
  return isLtMS;
};

const onDeleteItem = (item, filelist, setFileList) => {
  setFileList(filelist.filter(element => element.uid !== item.uid));
};

const UploadedElement = prop => {
  const { onHandleClick, info, ...otherProps } = prop;
  let fileName = info.name;
  if (fileName.length > 20) {
    fileName = `${fileName.substring(0, 20)}... .${info.type.substring(
      info.type.indexOf('/') + 1
    )}`;
  }
  let fileIcon;
  if (info.type === 'application/pdf') {
    fileIcon = 'file-pdf';
  } else if (info.type.startsWith('image')) {
    fileIcon = 'image';
  }
  return (
    <div className="upload-file-element">
      <Icon icon={fileIcon || 'paperclip'} className="nj-mr-2" />
      <span className="upload-file-name">{fileName}</span>
      <Icon
        icon="times-circle"
        onClick={onHandleClick}
        className="nj-ml-1 nj-delete-file"
        {...otherProps}
      />
    </div>
  );
};

const FileUpload = props => {
  const {
    label,
    width,
    height,
    aligned,
    maxSize,
    icon,
    text,
    onChange: onChangeHandler,
    ...otherProps
  } = props;
  const [fileList, setFileList] = useState([]);
  const onFileChange = ({ file }) => {
    setFileList(
      sizeValidation(file, maxSize) ? fileList.concat({ ...file }) : fileList
    );
    onChangeHandler({ file, fileList });
  };

  return (
    <div className={classNames({ aligned }, 'file-uploader')}>
      <div className="file-upload-prefix">
        <Upload
          multiple
          showUploadList={false}
          fileList={fileList}
          customRequest={() => {}}
          onChange={info => {
            onFileChange(info);
          }}
          {...otherProps}
        >
          <Button iconProps={{ icon: icon || 'upload' }}>
            {text || label}
          </Button>
        </Upload>
      </div>
      <div className="nj-flex-row" style={{ width, height }}>
        {fileList.map((element, index, arr) => (
          <UploadedElement
            key={element.uid}
            onHandleClick={() => onDeleteItem(element, arr, setFileList)}
            info={element}
          />
        ))}
      </div>
    </div>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  /** Set height */
  height: PropTypes.string,

  /** Set width */
  width: PropTypes.string,

  /** Set align display */
  aligned: PropTypes.bool,

  /** Set maximum file size in MegaBytes (MB) */
  maxSize: PropTypes.number,

  /** Set label text before */
  label: PropTypes.string,

  /** Handles change */
  onChange: PropTypes.func
};

FileUpload.defaultProps = {
  height: '100%',
  width: '100%',
  aligned: false,
  maxSize: 2,
  label: 'add attachments',
  onChange: /* istanbul ignore next */ () => {}
};
