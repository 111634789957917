import { defineMessages } from 'react-intl';

export const scope = 'members';

export default defineMessages({
  start: {
    id: `${scope}.start`,
    defaultMessage: 'Plan Start Date'
  },
  recurring: {
    id: `${scope}.recurring`,
    defaultMessage: 'Recurring membership'
  },
  active: {
    id: `${scope}.active`,
    defaultMessage: 'Active'
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending'
  },
  expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Expired'
  },
  noPlan: {
    id: `${scope}.noPlan`,
    defaultMessage: 'No plan assigned'
  },
  noBookingHours: {
    id: `${scope}.noBookingHours`,
    defaultMessage: 'No booking hours'
  },
  never: {
    id: `${scope}.never`,
    defaultMessage: 'Never'
  },
  expiry: {
    id: `${scope}.expiry`,
    defaultMessage: 'Expiry'
  },
  bookedHours: {
    id: `${scope}.bookedHours`,
    defaultMessage: 'Booked hours'
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'View profile'
  },
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Members'
  },
  spaceUsers: {
    id: `${scope}.spaceUsers`,
    defaultMessage: 'Space users'
  },
  membershipRequests: {
    id: `${scope}.membershipRequests`,
    defaultMessage: 'Membership requests'
  },
  addMember: {
    id: `${scope}.addMember`,
    defaultMessage: 'Add a member'
  },
  addInfo: {
    id: `${scope}.addInfo`,
    defaultMessage: 'Please add the member info'
  },
  updateInfo: {
    id: `${scope}.updateInfo`,
    defaultMessage: 'Please select a plan'
  },
  user: {
    id: `${scope}.user`,
    defaultMessage: 'User'
  },
  lastLogin: {
    id: `${scope}.lastLogin`,
    defaultMessage: 'Last Login'
  },
  status: {
    id: `${scope}.status`,
    defaultMessage: 'Status'
  },
  confirmed: {
    id: `${scope}.confirmed`,
    defaultMessage: 'Confirmed'
  },
  plan: {
    id: `${scope}.plan`,
    defaultMessage: 'Plan'
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions'
  },
  confirm: {
    id: `${scope}.confirm`,
    defaultMessage: 'Confirm'
  },
  decline: {
    id: `${scope}.decline`,
    defaultMessage: 'Decline'
  },
  editWorkspace: {
    id: `${scope}.Edit`,
    defaultMessage: 'Edit'
  },
  deleteWorkspace: {
    id: `${scope}.deleteWorkSpace`,
    defaultMessage: 'Delete'
  },
  remindMember: {
    id: `${scope}.remindMember`,
    defaultMessage: 'Remind Member about registration'
  },
  remindTitle: {
    id: `${scope}.remindTitle`,
    defaultMessage: 'Confirm remind member about registration'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Space name'
  },
  name_manager: {
    id: `${scope}.name_manager`,
    defaultMessage: 'Manager name'
  },
  surname_manager: {
    id: `${scope}.surname_manager`,
    defaultMessage: 'Manager surname'
  },
  email_manager: {
    id: `${scope}.email_manager`,
    defaultMessage: 'Manager email'
  },

  newCoworking: {
    id: `${scope}.new_coworking`,
    defaultMessage: 'New coworking'
  },
  timezone: {
    id: `${scope}.timezone`,
    defaultMessage: 'Time zone'
  },
  fqdn: {
    id: `${scope}.fqdn`,
    defaultMessage: 'Domain name'
  },
  create: {
    id: `${scope}.id`,
    defaultMessage: 'create'
  },
  date_format: {
    id: `${scope}.date_format`,
    defaultMessage: 'Date format'
  },
  dateFormat: {
    id: `${scope}.dateFormat`,
    defaultMessage: 'Date format'
  },
  submit: {
    id: `${scope}.edit`,
    defaultMessage: 'Submit'
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category'
  },
  content: {
    id: `${scope}.content`,
    defaultMessage: 'Content'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description'
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  coworking: {
    id: `${scope}.coworking`,
    defaultMessage: 'Coworking'
  },
  incubator: {
    id: `${scope}.incubator`,
    defaultMessage: 'Incubator'
  },
  notfound: {
    id: `${scope}.notfound`,
    defaultMessage: 'Not found'
  }
});
