import React from 'react';
import { Input as AntInput, Tooltip, InputNumber } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Input = props => {
  const { width, icon, info, label, type, ...otherProps } = props;

  if (type === 'number') {
    return (
      <>
        {label && <div className="input-label">{label}</div>}
        <InputNumber style={{ width }} {...otherProps} />
      </>
    );
  }
  if (type === 'text-area') {
    return (
      <>
        {label && <div className="input-label">{label}</div>}
        <AntInput.TextArea {...otherProps} />
      </>
    );
  }
  const DefaultInput = type === 'password' ? AntInput.Password : AntInput;
  return (
    <>
      {label && <div className="input-label">{label}</div>}
      <DefaultInput
        prefix={
          icon ? <FontAwesomeIcon className="icon" icon={icon} /> : undefined
        }
        suffix={
          info ? (
            <Tooltip title={info}>
              <FontAwesomeIcon className="icon" icon="question-circle" />
            </Tooltip>
          ) : undefined
        }
        style={{
          width
        }}
        {...otherProps}
      />
    </>
  );
};

Input.propTypes = {
  /** Disable text input */
  disabled: PropTypes.bool,

  /** Set input width */
  width: PropTypes.string,

  /** Set icon */
  icon: PropTypes.string,

  /** Diplay help information */
  info: PropTypes.string,

  /** Display label */
  label: PropTypes.string,

  /** AntIput type */
  type: PropTypes.string
};

Input.defaultProps = {
  disabled: false,
  width: undefined,
  icon: undefined,
  info: undefined,
  label: undefined,
  type: undefined
};

export default Input;
