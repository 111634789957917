import React from 'react';
import { Button as AntButton } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import chroma from 'chroma-js';

const Button = props => {
  const {
    width,
    height,
    iconProps,
    style,
    children,
    tag,
    backgroundColor,
    color,
    loading,
    type,
    className,
    ...otherProps
  } = props;
  let textColor;
  if (backgroundColor && !color) {
    textColor =
      chroma.contrast(backgroundColor, 'white') > 2 ? 'white' : 'black';
  }

  return (
    <AntButton
      style={{
        width,
        height,
        backgroundColor,
        color: textColor || color,
        ...style
      }}
      className={classNames(
        { 'tag-button': tag },
        { 'nj-text-uppercase': type !== 'link' },
        className
      )}
      loading={loading}
      type={type}
      {...otherProps}
    >
      {iconProps && !loading && (
        <FontAwesomeIcon className="icon-button" {...iconProps} />
      )}
      {children}
    </AntButton>
  );
};

Button.propTypes = {
  /** disabled state of button */
  disabled: PropTypes.bool,
  /** make background transparent and invert text and border colors */
  ghost: PropTypes.bool,
  /** option to fit button width to its parent width */
  block: PropTypes.bool,
  /** redirect url of link button */
  href: PropTypes.string,
  /** set the original html type of button */
  htmlType: PropTypes.string,
  /** set the icon of button */
  icon: PropTypes.string,
  /** can be set to small | large | -  */
  size: PropTypes.string,
  /** can be set to circle | round | - */
  shape: PropTypes.string,
  /** same as target attribute of a, works when href is specified */
  target: PropTypes.string,
  /** can be set to primary | secondary | danger | green | orange | dashed */
  type: PropTypes.string,
  /** set the height of button */
  height: PropTypes.string,
  /** set the width of button */
  width: PropTypes.string,
  /** set the style of button */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ),
  /** child of button */
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** FontAwsome Icon of button */
  iconProps: PropTypes.objectOf(PropTypes.string),
  /** set the loading status of button */
  loading: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      delay: PropTypes.number
    })
  ]),
  /** set the handler to handle click event */
  onClick: PropTypes.func,

  /** Change button to a tag */
  tag: PropTypes.bool,

  /** Change background color */
  backgroundColor: PropTypes.string,

  /** Change font color */
  color: PropTypes.string
};
Button.defaultProps = {
  disabled: false,
  ghost: false,
  block: false,
  icon: undefined,
  htmlType: 'button',
  href: undefined,
  height: undefined,
  size: 'default',
  shape: undefined,
  style: undefined,
  target: undefined,
  type: 'default',
  loading: false,
  width: undefined,
  children: undefined,
  iconProps: undefined,
  onClick: /* istanbul ignore next */ () => {},
  tag: false,
  backgroundColor: undefined,
  color: undefined
};

export default Button;
