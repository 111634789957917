import {
  COWORKINGS_FETCH,
  COWORKING_DELETE,
  COWORKING_CREATE,
  COWORKING_UPDATE,
  COWORKINGS_STORE,
  WAITINGLIST_STORE,
  WAITINGLIST_FETCH,
  REPORTS_FETCH,
  REPORTS_SET
} from './constants';

export const coworkingDelete = (id, fetchStart) => {
  return {
    type: COWORKING_DELETE,
    id,
    fetchStart
  };
};

export const createCoworkingAction = (payload, destroyModal, actions) => {
  return {
    type: COWORKING_CREATE,
    payload,
    destroyModal,
    actions
  };
};
export const updateCoworkingAction = (id, payload, actions, destroyModal) => {
  return {
    type: COWORKING_UPDATE,
    id,
    payload,
    actions,
    destroyModal
  };
};

export const coworkingsFetch = () => {
  return {
    type: COWORKINGS_FETCH
  };
};

export const storeCoworkings = payload => {
  return {
    type: COWORKINGS_STORE,
    payload
  };
};

export const fetchWaitingList = () => {
  return {
    type: WAITINGLIST_FETCH
  };
};

export const StoreWaitngList = payload => {
  return {
    type: WAITINGLIST_STORE,
    payload
  };
};
export const reportsFetchRequest = () => {
  return {
    type: REPORTS_FETCH
  };
};

export const setReports = payload => {
  return {
    type: REPORTS_SET,
    payload
  };
};
