import produce from 'immer';
import dayjs from 'dayjs';
import { PURGE } from 'redux-persist';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  TRIGGER_SIDEBAR,
  TRIGGER_DRAWER,
  SET_AUTH,
  SET_INFO,
  SET_USER
} from './constants';

export const initialState = {
  error: '',
  loading: false,
  sidebarStatus: false,
  drawerStatus: false,
  message: '',
  user: {
    token: null,
    expiresAt: 0,
    membership: { plan_id: null, end_date: -1 }
  },
  coworking: { logo: null, type: 'coworking' }
};
/* eslint-disable default-case, no-param-reassign, consistent-return */
export default (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case FETCH_START:
        draft.error = '';
        draft.message = '';
        draft.loading = true;
        break;

      case FETCH_SUCCESS:
      case HIDE_MESSAGE:
        draft.error = '';
        draft.message = '';
        draft.loading = false;
        break;

      case SHOW_MESSAGE:
        draft.error = '';
        draft.message = payload;
        draft.loading = false;
        break;

      case FETCH_ERROR:
        draft.error = payload;
        draft.message = '';
        draft.loading = false;
        break;

      case TRIGGER_SIDEBAR:
        draft.sidebarStatus = !state.sidebarStatus;
        break;

      case TRIGGER_DRAWER:
        draft.drawerStatus = !state.drawerStatus;
        break;

      case SET_AUTH:
        draft.user.token = payload.token;
        draft.user.expiresAt = dayjs().add(payload.expiresIn, 'second').unix();
        break;

      case SET_INFO:
        draft.coworking = payload;
        break;

      case SET_USER:
        draft.user = {
          ...state.user,
          ...payload
        };
        break;

      case PURGE:
        return initialState;

      default:
        return state;
    }
  });
};
