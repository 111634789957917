import { createSelector } from 'reselect';

import { initialState } from './reducer';

const selectCoworkingState = state => state.coworking || initialState;

export const selectCoworkings = createSelector(
  selectCoworkingState,
  state => state
);
export const selectWaitingList = createSelector(
  selectCoworkingState,
  state => state.waitngList
);
export const selectReportsList = createSelector(
  selectCoworkingState,
  state => state.reportsList
);
