import { defineMessages } from 'react-intl';

export const scope = 'app';

export default defineMessages({
  accepted: {
    id: `${scope}.validation.accepted`,
    defaultMessage: 'The {attribute} must be accepted.'
  },
  active_url: {
    id: `${scope}.validation.active_url`,
    defaultMessage: 'The {attribute} is not a valid URL.'
  },
  after: {
    id: `${scope}.validation.after`,
    defaultMessage: 'The {attribute} must be a date after {date}.'
  },
  after_or_equal: {
    id: `${scope}.validation.after_or_equal`,
    defaultMessage: 'The {attribute} must be a date after or equal to {date}.'
  },
  alpha: {
    id: `${scope}.validation.alpha`,
    defaultMessage: 'The {attribute} may only contain letters.'
  },
  alpha_underscore: {
    id: `${scope}.validation.alpha_underscore`,
    defaultMessage:
      'The {attribute} may only contain letters, numbers and underscores.'
  },
  alpha_num: {
    id: `${scope}.validation.alpha_num`,
    defaultMessage: 'The {attribute} may only contain letters and numbers.'
  },
  array: {
    id: `${scope}.validation.array`,
    defaultMessage: 'The {attribute} must be an array.'
  },
  before: {
    id: `${scope}.validation.before`,
    defaultMessage: 'The {attribute} must be a date before {date}.'
  },
  before_or_equal: {
    id: `${scope}.validation.before_or_equal`,
    defaultMessage: 'The {attribute} must be a date before or equal to {date}.'
  },
  boolean: {
    id: `${scope}.validation.boolean`,
    defaultMessage: 'The {attribute} field must be true or false.'
  },
  confirmed: {
    id: `${scope}.validation.confirmed`,
    defaultMessage: 'The {attribute} confirmation does not match.'
  },
  date: {
    id: `${scope}.validation.date`,
    defaultMessage: 'The {attribute} is not a valid date.'
  },
  date_equals: {
    id: `${scope}.validation.date_equals`,
    defaultMessage: 'The {attribute} must be a date equal to {date}.'
  },
  date_format: {
    id: `${scope}.validation.date_format`,
    defaultMessage: 'The {attribute} does not match the format {format}.'
  },
  different: {
    id: `${scope}.validation.different`,
    defaultMessage: 'The {attribute} and {other} must be different.'
  },
  digits: {
    id: `${scope}.validation.digits`,
    defaultMessage: 'The {attribute} must be {digits} digits.'
  },
  digits_between: {
    id: `${scope}.validation.digits_between`,
    defaultMessage: 'The {attribute} must be between {min} and {max} digits.'
  },
  dimensions: {
    id: `${scope}.validation.dimensions`,
    defaultMessage: 'The {attribute} has invalid image dimensions.'
  },
  distinct: {
    id: `${scope}.validation.distinct`,
    defaultMessage: 'The {attribute} field has a duplicate value.'
  },
  email: {
    id: `${scope}.validation.email`,
    defaultMessage: 'The {attribute} must be a valid email address.'
  },
  exists: {
    id: `${scope}.validation.exists`,
    defaultMessage: 'The selected {attribute} is invalid.'
  },
  file: {
    id: `${scope}.validation.file`,
    defaultMessage: 'The {attribute} must be a file.'
  },
  filled: {
    id: `${scope}.validation.filled`,
    defaultMessage: 'The {attribute} field must have a value.'
  },
  image: {
    id: `${scope}.validation.image`,
    defaultMessage: 'The {attribute} must be an image.'
  },
  in: {
    id: `${scope}.validation.in`,
    defaultMessage: 'The selected {attribute} is invalid.'
  },
  in_array: {
    id: `${scope}.validation.in_array`,
    defaultMessage: 'The {attribute} field does not exist in {other}.'
  },
  integer: {
    id: `${scope}.validation.integer`,
    defaultMessage: 'The {attribute} must be an integer.'
  },
  ip: {
    id: `${scope}.validation.ip`,
    defaultMessage: 'The {attribute} must be a valid IP address.'
  },
  ipv4: {
    id: `${scope}.validation.ipv4`,
    defaultMessage: 'The {attribute} must be a valid IPv4 address.'
  },
  ipv6: {
    id: `${scope}.validation.ipv6`,
    defaultMessage: 'The {attribute} must be a valid IPv6 address.'
  },
  json: {
    id: `${scope}.validation.json`,
    defaultMessage: 'The {attribute} must be a valid JSON string.'
  },
  max: {
    id: `${scope}.validation.max.numeric`,
    defaultMessage: 'The {attribute} may not be greater than {max}.'
  },
  maxFileSize: {
    id: `${scope}.validation.maxFileSize`,
    defaultMessage: 'The {attribute} may not be greater than {max} Mb.'
  },
  mimes: {
    id: `${scope}.validation.mimes`,
    defaultMessage: 'The {attribute} must be a file of type: {values}.'
  },
  mimetypes: {
    id: `${scope}.validation.mimetypes`,
    defaultMessage: 'The {attribute} must be a file of type: {values}.'
  },
  min: {
    id: `${scope}.validation.min.numeric`,
    defaultMessage: 'The {attribute} must be at least {min}.'
  },
  not_in: {
    id: `${scope}.validation.not_in`,
    defaultMessage: 'The selected {attribute} is invalid.'
  },
  not_regex: {
    id: `${scope}.validation.not_regex`,
    defaultMessage: 'The {attribute} format is invalid.'
  },
  numeric: {
    id: `${scope}.validation.numeric`,
    defaultMessage: 'The {attribute} must be a number.'
  },
  positive: {
    id: `${scope}.validation.positive`,
    defaultMessage: 'The {attribute} must be a positive number.'
  },
  present: {
    id: `${scope}.validation.present`,
    defaultMessage: 'The {attribute} field must be present.'
  },
  regex: {
    id: `${scope}.validation.regex`,
    defaultMessage: 'The {attribute} format is invalid.'
  },
  required: {
    id: `${scope}.validation.required`,
    defaultMessage: 'The {attribute} field is required.'
  },
  required_if: {
    id: `${scope}.validation.required_if`,
    defaultMessage: 'The {attribute} field is required when {other} is {value}.'
  },
  required_unless: {
    id: `${scope}.validation.required_unless`,
    defaultMessage:
      'The {attribute} field is required unless {other} is in {values}.'
  },
  required_with: {
    id: `${scope}.validation.required_with`,
    defaultMessage:
      'The {attribute} field is required when {values} is present.'
  },
  required_with_all: {
    id: `${scope}.validation.required_with_all`,
    defaultMessage:
      'The {attribute} field is required when {values} are present.'
  },
  required_without: {
    id: `${scope}.validation.required_without`,
    defaultMessage:
      'The {attribute} field is required when {values} is not present.'
  },
  required_without_all: {
    id: `${scope}.validation.required_without_all`,
    defaultMessage:
      'The {attribute} field is required when none of {values} are present.'
  },
  same: {
    id: `${scope}.validation.same`,
    defaultMessage: 'The {attribute} and {other} must match.'
  },
  starts_with: {
    id: `${scope}.validation.starts_with`,
    defaultMessage:
      'The {attribute} must start with one of the following: {values}'
  },
  string: {
    id: `${scope}.validation.string`,
    defaultMessage: 'The {attribute} must be a string.'
  },
  timezone: {
    id: `${scope}.validation.timezone`,
    defaultMessage: 'The {attribute} must be a valid zone.'
  },
  unique: {
    id: `${scope}.validation.unique`,
    defaultMessage: 'The {attribute} has already been taken.'
  },
  uploaded: {
    id: `${scope}.validation.uploaded`,
    defaultMessage: 'The {attribute} failed to upload.'
  },
  url: {
    id: `${scope}.validation.url`,
    defaultMessage: 'The {attribute} format is invalid.'
  },
  uuid: {
    id: `${scope}.validation.uuid`,
    defaultMessage: 'The {attribute} must be a valid UUID.'
  },
  registerConfirmTitle: {
    id: `${scope}.registerConfirmTitle`,
    defaultMessage: 'Are you sure you want to {status} for this event ?'
  },
  deleteConfirmTitle: {
    id: `${scope}.deleteConfirmTitle`,
    defaultMessage: 'Are you sure you want to delete this {attribute} ?'
  },
  confirmContent: {
    id: `${scope}.confirmContent`,
    defaultMessage: 'Post name: {attribute}'
  },
  yes: {
    id: `${scope}.yes`,
    defaultMessage: 'Yes'
  },
  no: {
    id: `${scope}.no`,
    defaultMessage: 'No'
  },
  ok: {
    id: `${scope}.ok`,
    defaultMessage: 'Ok'
  },
  cancel: {
    id: `${scope}.cancel`,
    defaultMessage: 'Cancel'
  },
  close: {
    id: `${scope}.close`,
    defaultMessage: 'Close'
  },
  closed: {
    id: `${scope}.closed`,
    defaultMessage: 'Closed'
  },
  submit: {
    id: `${scope}.submit`,
    defaultMessage: 'Submit'
  },
  delete: {
    id: `${scope}.delete`,
    defaultMessage: 'Delete'
  },
  edit: {
    id: `${scope}.edit`,
    defaultMessage: 'Edit'
  },
  add: {
    id: `${scope}.add`,
    defaultMessage: 'Add'
  },
  send: {
    id: `${scope}.send`,
    defaultMessage: 'Send'
  },
  publish: {
    id: `${scope}.publish`,
    defaultMessage: 'Publish'
  },
  report: {
    id: `${scope}.report`,
    defaultMessage: 'Report'
  },
  all: {
    id: `${scope}.all`,
    defaultMessage: 'All'
  },
  description: {
    id: `${scope}.description`,
    defaultMessage: 'Description'
  },
  title: {
    id: `${scope}.title`,
    defaultMessage: 'Title'
  },
  name: {
    id: `${scope}.name`,
    defaultMessage: 'Name'
  },
  type: {
    id: `${scope}.type`,
    defaultMessage: 'Type'
  },
  category: {
    id: `${scope}.category`,
    defaultMessage: 'Category'
  },
  tickets: {
    id: `${scope}.tickets`,
    defaultMessage: 'Tickets'
  },
  assets: {
    id: `${scope}.assets`,
    defaultMessage: 'Assets'
  },
  events: {
    id: `${scope}.events`,
    defaultMessage: 'Events'
  },
  attachments: {
    id: `${scope}.attachments`,
    defaultMessage: 'Attachments'
  },
  addattachments: {
    id: `${scope}.addattachments`,
    defaultMessage: 'Add attachments'
  },
  publishedAt: {
    id: `${scope}.publishedAt`,
    defaultMessage: 'Published {date}'
  },
  publishedBy: {
    id: `${scope}.publishedBy`,
    defaultMessage: 'By {author}'
  },
  discussion: {
    id: `${scope}.discussion`,
    defaultMessage: 'Discussion'
  },
  replyedAt: {
    id: `${scope}.replyedAt`,
    defaultMessage: '@{author} replyed at {date}'
  },
  reply: {
    id: `${scope}.reply`,
    defaultMessage: 'You can reply here'
  },
  error: {
    id: `${scope}.error`,
    defaultMessage: 'Error'
  },
  notfound: {
    id: `${scope}.notfound`,
    defaultMessage: 'Not found'
  },
  noDescription: {
    id: `${scope}.noDescription`,
    defaultMessage: `No description`
  },
  free: {
    id: `${scope}.free`,
    defaultMessage: `Free`
  },
  likes: {
    id: `${scope}.likes`,
    defaultMessage:
      '{likeCount, number} {likeCount, plural, one {like} other {likes}}'
  },
  comments: {
    id: `${scope}.comments`,
    defaultMessage:
      '{commentsCount, number} {commentsCount, plural, one {comment} other {comments}}'
  },
  allCategories: {
    id: `${scope}.allCategories`,
    defaultMessage: 'All categories'
  },
  general: {
    id: `${scope}.general`,
    defaultMessage: 'General information'
  },
  contact: {
    id: `${scope}.contact`,
    defaultMessage: 'Contact information'
  },
  price: {
    id: `${scope}.price`,
    defaultMessage: 'price'
  },
  plans: {
    id: `${scope}.plans`,
    defaultMessage: 'Plans'
  },
  language: {
    id: `${scope}.language`,
    defaultMessage: 'Language'
  },
  country: {
    id: `${scope}.country`,
    defaultMessage: 'Country'
  },
  goBack: {
    id: `${scope}.back`,
    defaultMessage: 'Go back'
  },
  website: {
    id: `${scope}.website`,
    defaultMessage: 'Website'
  },
  public: {
    id: `${scope}.public`,
    defaultMessage: 'Public'
  },
  private: {
    id: `${scope}.private`,
    defaultMessage: 'Private'
  },
  pending: {
    id: `${scope}.pending`,
    defaultMessage: 'Pending'
  },
  members: {
    id: `${scope}.members`,
    defaultMessage: 'Space users'
  },
  rooms: {
    id: `${scope}.rooms`,
    defaultMessage: 'Rooms'
  },
  expired: {
    id: `${scope}.expired`,
    defaultMessage: 'Your plan has expired'
  },
  plan: {
    id: `${scope}.plan`,
    defaultMessage: 'Plan'
  },
  moreDetails: {
    id: `${scope}.moreDetails`,
    defaultMessage: 'More details'
  },
  visibility: {
    id: `${scope}.visibility`,
    defaultMessage: 'Visibility'
  },
  location: {
    id: `${scope}.location`,
    defaultMessage: 'Address'
  },
  feed: {
    id: `${scope}.feed`,
    defaultMessage: 'Feed'
  },
  lostfound: {
    id: `${scope}.lostfound`,
    defaultMessage: 'Lost and Found'
  },
  timeTable: {
    id: `${scope}.timeTable`,
    defaultMessage: 'Time Table'
  },
  duration: {
    id: `${scope}.duration`,
    defaultMessage: 'duration'
  },
  resolved: {
    id: `${scope}.resolved`,
    defaultMessage: 'resolved'
  },
  markClosed: {
    id: `${scope}.markClosed`,
    defaultMessage: 'Mark as closed'
  },
  open: {
    id: `${scope}.open`,
    defaultMessage: 'Open'
  },
  reopen: {
    id: `${scope}.reopen`,
    defaultMessage: 'Reopen'
  },
  obWelcomeTitle: {
    id: `${scope}.obWelcomeTitle`,
    defaultMessage: 'Welcome !'
  },
  obWelcomeContent: {
    id: `${scope}.obWelcomeContent`,
    defaultMessage:
      'Thank you for choosing Njano!\nMy name is Jasmin and I will help you through the basics and the necessities of your account.If you have any other questions, please don’t hesitate to reach us.Let’s get started!'
  },
  obDashboardTitle: {
    id: `${scope}.obDashboardTitle`,
    defaultMessage: 'Your Dashboard:'
  },
  obDashboardContent: {
    id: `${scope}.obDashboardContent`,
    defaultMessage:
      'This is where you can find real-time data visuals at one glance, in one place!(And Guess what? You can also customize it)'
  },
  obProfileLinkContent: {
    id: `${scope}.obProfileLinkContent`,
    defaultMessage:
      "This is where you log out and edit your personal profile. Try clicking on your name then on 'My account'"
  },
  obProfileTitle: {
    id: `${scope}.obPersonalProfileLink`,
    defaultMessage: 'Your Personal Profile:'
  },
  obProfileContent: {
    id: `${scope}.obProfileContent`,
    defaultMessage:
      'This is where you can change your password and add details about you.'
  },
  obOrganisationTitle: {
    id: `${scope}.obOrganisationTitle`,
    defaultMessage: 'Your Organisation profile:'
  },
  obOrganisationContent: {
    id: `${scope}.obOrganisationContent`,
    defaultMessage:
      "Build the foundations of your space! (Click on 'Space users')"
  },
  obOrganisationContent2: {
    id: `${scope}.obOrganisationContent2`,
    defaultMessage:
      'Personalize your space by uploading your logo, modifying your color scheme, and adding more information.'
  },
  obManagementTitle: {
    id: `${scope}.obManagementTitle`,
    defaultMessage: 'Internal Management:'
  },
  obManagementContent: {
    id: `${scope}.obManagementContent`,
    defaultMessage:
      'This section contains all the tools that will help you manage your space, including the management of users, plans, events, rooms, and reported issues.'
  },
  obEventsLinkContent: {
    id: `${scope}.obEventsLinkContent`,
    defaultMessage: "Try clicking on 'events'"
  },
  obEventsContent: {
    id: `${scope}.obEventsContent`,
    defaultMessage:
      'This is where you can create events or see upcoming and past events.'
  },
  obEventsCreateContent: {
    id: `${scope}.obEventsCreateContent`,
    defaultMessage:
      'You can add the time of the event, its capacity, and its location whether inside or outside your organization.'
  },
  obEventsButton: {
    id: `${scope}.obEventsButton`,
    defaultMessage: "Try clicking on the '+' button"
  },
  obPlansLinkContent: {
    id: `${scope}.obPlansLinkContent`,
    defaultMessage:
      "Let your users know what plans you have to offer them! Click on 'Plans'"
  },
  obPlansLinkContentMember: {
    id: `${scope}.obPlansLinkContentMember`,
    defaultMessage: "Let’s see what the space has to offer! Click on 'Plans'"
  },
  obPlansContent: {
    id: `${scope}.obPlansContent`,
    defaultMessage:
      'Add as many plans as you want and customize them. You can put as many details as you want including specific access hours, price, discounts, and more!'
  },
  obPlansContentMember: {
    id: `${scope}.obPlansContentMember`,
    defaultMessage:
      'You can look through the plans and choose the one that works for you the best.'
  },
  obLostLinkContent: {
    id: `${scope}.obLostLinkContent`,
    defaultMessage: 'You lost or found something? Click here!'
  },
  obLostContent: {
    id: `${scope}.obLostContent`,
    defaultMessage:
      'Here you can see the missing items reported or found and you can add a new post too.'
  },
  obTicketsLinkContent: {
    id: `${scope}.obTicketsLinkContent`,
    defaultMessage: 'Do you have an issue you want to report? Click here'
  },
  obTicketsContent: {
    id: `${scope}.obTicketsContent`,
    defaultMessage:
      'Internet is slow? Did the coffee spill on the floor? Click here to report this issue and a staff member will be there to assist you as soon as possible! You can also help with a reported issue if it concerns you! '
  },
  obRoomsLinkContent: {
    id: `${scope}.obRoomsLinkContent`,
    defaultMessage: 'Do you want to check on your rooms ? Click here'
  },
  obRoomsContent: {
    id: `${scope}.obRoomsContent`,
    defaultMessage:
      'You can see all your available rooms and their condition. You can also add a new room.'
  },
  obFinanceTitle: {
    id: `${scope}.obFinanceTitle`,
    defaultMessage: 'Financial Management'
  },
  obFinanceContent: {
    id: `${scope}.obFinanceContent`,
    defaultMessage:
      'This section contains all the options related to managing the finances in your space, including payments, expenses, and assets.'
  },
  obPaymentsLinkContent: {
    id: `${scope}.obPaymentsLinkContent`,
    defaultMessage: 'Placeholder'
  },
  obPaymentsContent: {
    id: `${scope}.obPaymentsContent`,
    defaultMessage: 'Placeholder'
  },
  obExpensesLink: {
    id: `${scope}.obExpensesLink`,
    defaultMessage: 'Placeholder'
  },
  obExpenses: {
    id: `${scope}.obExpenses`,
    defaultMessage: 'Placeholder'
  },
  obAssetsLink: {
    id: `${scope}.obAssetsLink`,
    defaultMessage:
      "Here you will find all the tools needed to manage the various assets in your space. Click on 'assests'"
  },
  obAssetsContent: {
    id: `${scope}.obAssetsContent`,
    defaultMessage:
      'Adding a new asset is easy, you should just fill out the necessary information such as its category, status, and value, and you’re all set! Try it out!'
  },
  obSocialTitle: {
    id: `${scope}.obSocialTitle`,
    defaultMessage: 'Social Networking:'
  },
  obSocialContent: {
    id: `${scope}.obSocialContent`,
    defaultMessage:
      'This section contains all networking tools including social circles, a social feed, and a live chat.'
  },
  obCircleLinkContent: {
    id: `${scope}.obCircleLinkContent`,
    defaultMessage:
      "Social circles are public or closed groups with common interests.They can be international or within your coworking space or your country. Click on 'circles'"
  },
  obCircleContent: {
    id: `${scope}.obCircleContent`,
    defaultMessage:
      'You can join one of the public groups or create and manage your own circle'
  },
  obFeedLinkContent: {
    id: `${scope}.obFeedLinkContent`,
    defaultMessage:
      "This is where you can see the latest activities from your social circles. Click on 'feed'"
  },
  obFeedContent: {
    id: `${scope}.obFeedContent`,
    defaultMessage:
      'You can comment on the posts, like them and interact with the other circle members.'
  },
  obChatLinkContent: {
    id: `${scope}.obChatLinkContent`,
    defaultMessage:
      '… or you can carry on a private conversation with any Njano user. Click here'
  },
  obChatContent: {
    id: `${scope}.obChatContent`,
    defaultMessage:
      'You can see your latest chats, find a contact from your coworking space, or search for an Njano user from another space.'
  },
  obEndTitle: {
    id: `${scope}.obEndTitle`,
    defaultMessage: 'End of the tour'
  },
  obEndContent: {
    id: `${scope}.obEndContent`,
    defaultMessage: 'Thank you for paying attention during this tour.'
  },
  notifications: {
    id: `${scope}.notifications`,
    defaultMessage: 'Notifications'
  },
  obNotificationsContent: {
    id: `${scope}.obNotificationsContent`,
    defaultMessage: `Stay updated with your most recent notifications!`
  },
  liveChat: {
    id: `${scope}.liveChat`,
    defaultMessage: `Live Chat`
  },
  obLiveChatContent: {
    id: `${scope}.obLiveChatContent`,
    defaultMessage:
      'Click here for an easy access to your private conversations!'
  },
  obMembershipLink: {
    id: `${scope}.obMembershipLink`,
    defaultMessage: "click on 'membership'"
  },
  obMembershiptitle: {
    id: `${scope}.obMembershiptitle`,
    defaultMessage: 'Your membership:'
  },
  obMembershipContent: {
    id: `${scope}.obMembershipContent`,
    defaultMessage: 'You can also check your account and edit it through here!'
  },
  skipConfirmTitle: {
    id: `${scope}.skipConfirmTitle`,
    defaultMessage: 'Skip the tutorial ?'
  },
  skipConfirmContent: {
    id: `${scope}.skipConfirmContent`,
    defaultMessage:
      'You are about to skip the tutorial. You can later reactive it in your settings'
  },
  skip: {
    id: `${scope}.skip`,
    defaultMessage: 'Skip'
  },
  advanceTax: {
    id: 'finance.advanceTax',
    defaultMessage: 'Advance Tax'
  },
  employeeAdvance: {
    id: 'finance.employeeAdvance',
    defaultMessage: 'Employee Advance'
  },
  prepaidExpenses: {
    id: 'finance.prepaidExpenses',
    defaultMessage: 'Prepaid Expenses'
  },
  pettyCash: {
    id: 'finance.pettyCash',
    defaultMessage: 'Petty Cash'
  },
  undepositedFunds: {
    id: 'finance.undepositedFunds',
    defaultMessage: 'Undeposited Funds'
  },
  furnitureAndEquipment: {
    id: 'finance.furnitureAndEquipment',
    defaultMessage: 'Furniture and Equipment'
  },
  inventoryAsset: {
    id: 'finance.inventoryAsset',
    defaultMessage: 'Inventory Asset'
  },
  advertisingAndMarketing: {
    id: 'finance.advertisingAndMarketing',
    defaultMessage: 'Advertising And Marketing'
  },
  automobileExpense: {
    id: 'finance.automobileExpense',
    defaultMessage: 'Automobile Expense'
  },
  badDebt: {
    id: 'finance.badDebt',
    defaultMessage: 'Bad Debt'
  },
  BankFeesAndCharges: {
    id: 'finance.BankFeesAndCharges',
    defaultMessage: 'Bank Fees and Charges'
  },
  consultantExpense: {
    id: 'finance.consultantExpense',
    defaultMessage: 'Consultant Expense'
  },
  creditCardCharges: {
    id: 'finance.creditCardCharges',
    defaultMessage: 'Credit Card Charges'
  },
  depreciationExpense: {
    id: 'finance.depreciationExpense',
    defaultMessage: 'Depreciation Expense'
  },
  iTAndInternetExpenses: {
    id: 'finance.iTAndInternetExpenses',
    defaultMessage: 'IT and Internet Expenses'
  },
  janitorialExpense: {
    id: 'finance.janitorialExpense',
    defaultMessage: 'Janitorial Expense'
  },
  lodging: {
    id: 'finance.lodging',
    defaultMessage: 'Lodging'
  },
  mealsAndEntertainment: {
    id: 'finance.mealsAndEntertainment',
    defaultMessage: 'Meals and Entertainment'
  },
  officeSupplies: {
    id: 'finance.officeSupplies',
    defaultMessage: 'Office Supplies'
  },
  otherExpenses: {
    id: 'finance.otherExpenses',
    defaultMessage: 'Office Supplies'
  },
  postage: {
    id: 'finance.postage',
    defaultMessage: 'Postage'
  },
  printingAndStationery: {
    id: 'finance.printingAndStationery',
    defaultMessage: 'Printing and Stationery'
  },
  rentExpense: {
    id: 'finance.rentExpense',
    defaultMessage: 'Rent Expense'
  },
  repairsAndMaintenance: {
    id: 'finance.repairsAndMaintenance',
    defaultMessage: 'Repairs and Maintenance'
  },
  salariesAndEmployeeWages: {
    id: 'finance.salariesAndEmployeeWages',
    defaultMessage: 'Salaries and Employee Wages'
  },
  telephoneExpense: {
    id: 'finance.telephoneExpense',
    defaultMessage: 'Telephone Expense'
  },
  travelExpense: {
    id: 'finance.travelExpense',
    defaultMessage: 'Travel Expense'
  },
  uncategorized: {
    id: 'finance.uncategorized',
    defaultMessage: 'Uncategorized'
  },
  costOfGoodsSold: {
    id: 'finance.costOfGoodsSold',
    defaultMessage: 'Cost of Goods Sold'
  },
  exchangeGainOrLoss: {
    id: 'finance.exchangeGainOrLoss',
    defaultMessage: 'Exchange Gain or Loss'
  },
  bank: {
    id: 'finance.bank',
    defaultMessage: 'Bank'
  },
  frontDesk: {
    id: `${scope}.frontDesk`,
    defaultMessage: 'Front Desk'
  },
  viewItem: {
    id: `${scope}.viewItem`,
    defaultMessage: 'View item'
  },
  actions: {
    id: `${scope}.actions`,
    defaultMessage: 'Actions'
  },
  filterTitle: {
    id: `${scope}.filterTitle`,
    defaultMessage: 'Filter'
  },
  filterConfirm: {
    id: `${scope}.filterConfirm`,
    defaultMessage: 'Confirm'
  },
  filterReset: {
    id: `${scope}.filterReset`,
    defaultMessage: 'Reset'
  },
  emptyText: {
    id: `${scope}.emptyText`,
    defaultMessage: 'Empty'
  },
  view: {
    id: `${scope}.view`,
    defaultMessage: 'view'
  },
  integrations: {
    id: `${scope}.integration`,
    defaultMessage: 'Integrations'
  },
  claims: {
    id: `${scope}.claims`,
    defaultMessage: 'Claims'
  },
  readMore: {
    id: `${scope}.readMore`,
    defaultMessage: 'show more'
  },
  readLess: {
    id: `${scope}.readLess`,
    defaultMessage: 'show less'
  },
  fb: {
    id: `${scope}.fb`,
    defaultMessage: 'Facebook Profile'
  },
  ig: {
    id: `${scope}.ig`,
    defaultMessage: 'Instagram Profile'
  },
  tw: {
    id: `${scope}.tw`,
    defaultMessage: 'Twitter Profile'
  },
  lkdn: {
    id: `${scope}.in`,
    defaultMessage: 'Linkedin link'
  },
  yt: {
    id: `${scope}.yt`,
    defaultMessage: 'Youtube video'
  },
  remind: {
    id: `${scope}.remind`,
    defaultMessage: 'Remind'
  }
});
