import React, { useState, useEffect } from 'react';
import { Menu, Button as AntButton, Dropdown } from 'antd';
import { Document, Page, pdfjs } from 'react-pdf';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Button } from '@njano';

const { Item: MenuItem } = Menu;
const ButtonGroup = AntButton.Group;
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const AttachmentsPreviewer = props => {
  // props
  const {
    // data
    attachments,
    // messages
    noAttachmentsMessage = 'No attachments found',
    loadingFilesMessage = 'Loading files...',
    imageNotFoundMessage = 'Image not found',
    failedToLoadPdfMessage = 'Failed to load PDF file'
  } = props;

  // state
  const [selectedAttachment, setSelectedAttachment] = useState(
    attachments && attachments.length !== 0
      ? { ...attachments[0], index: 0 }
      : {}
  );
  const [pdfNumberOfPages, setPdfNumberOfPages] = useState(0);
  const [pdfScale, setPdfScale] = useState(1);
  const [pdfSelect, setPdfSelect] = useState(true);

  useEffect(() => {
    if (attachments && attachments.length !== 0) {
      setSelectedAttachment({ ...attachments[0], index: 0 });
      setPdfNumberOfPages(0);
    }
  }, [attachments]);

  // methods
  const dispalyFileName = fileName => {
    if (!fileName) {
      return loadingFilesMessage;
    }
    if (fileName.length > 12) {
      return `${fileName.substring(0, 12)}... .${fileName.substring(
        fileName.lastIndexOf('.') + 1
      )}`;
    }
    return fileName;
  };

  const displayFileIcon = fileName => {
    if (!fileName) {
      return 'paperclip';
    }
    if (fileName.toLowerCase().endsWith('.pdf')) {
      return 'file-pdf';
    }
    return 'image';
  };

  const zoomLister = () => {
    return (
      <Menu>
        <MenuItem onClick={() => setPdfScale(0.25)} key="zoom-25">
          25%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(0.5)} key="zoom-50">
          50%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(0.75)} key="zoom-75">
          75%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(1)} key="zoom-100">
          100%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(2)} key="zoom-200">
          200%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(3)} key="zoom-300">
          300%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(4)} key="zoom-400">
          400%
        </MenuItem>
        <MenuItem onClick={() => setPdfScale(5)} key="zoom-500">
          500%
        </MenuItem>
      </Menu>
    );
  };

  const attachmentsLister = () => {
    return (
      <Menu>
        {attachments.map((attachment, index) => {
          return (
            <MenuItem
              key={attachment.name}
              onClick={() => setSelectedAttachment({ ...attachment, index })}
            >
              <Icon
                icon={displayFileIcon(attachment.name)}
                className="nj-mr-2"
              />
              {attachment.name}
            </MenuItem>
          );
        })}
      </Menu>
    );
  };

  return (
    <>
      {(attachments === null && <>{noAttachmentsMessage}</>) || (
        <>
          {(attachments && attachments.length === 0 && (
            <>{noAttachmentsMessage}</>
          )) || (
            <>
              <div className="nj-d-flex nj-justify-content-center">
                <ButtonGroup className="nj-w-100 nj-text-center">
                  <Button
                    disabled={
                      (attachments && attachments.length === 1) ||
                      selectedAttachment.index === 0
                    }
                    className="nj-mb-0"
                    iconProps={{ icon: 'angle-left' }}
                    onClick={() => {
                      setSelectedAttachment({
                        ...attachments.filter(
                          (_, index) => index === selectedAttachment.index - 1
                        )[0],
                        index: selectedAttachment.index - 1
                      });
                    }}
                  />
                  <Dropdown overlay={attachmentsLister()} trigger={['click']}>
                    <Button
                      className="nj-attachments-previewer-navigator nj-w-100 nj-mb-0"
                      iconProps={{
                        icon: displayFileIcon(selectedAttachment.name)
                      }}
                    >
                      {dispalyFileName(selectedAttachment.name)}
                    </Button>
                  </Dropdown>
                  <Button
                    disabled={
                      attachments.length === 1 ||
                      selectedAttachment.index === attachments.length - 1
                    }
                    className="nj-mb-0"
                    iconProps={{ icon: 'angle-right' }}
                    onClick={() => {
                      setSelectedAttachment({
                        ...attachments.filter(
                          (_, index) => index === selectedAttachment.index + 1
                        )[0],
                        index: selectedAttachment.index + 1
                      });
                    }}
                  />
                </ButtonGroup>
              </div>
              <div className="nj-mb-2" />
              {selectedAttachment.name && (
                <>
                  {(selectedAttachment.name.toLowerCase().endsWith('.pdf') && (
                    <div
                      className={classNames(
                        'nj-attachments-previewer-pdf-base-container',
                        {
                          'nj-attachments-previewer-pdf-disable-select': !pdfSelect
                        }
                      )}
                    >
                      <div className="nj-attachments-previewer-pdf-controls nj-d-flex">
                        <ButtonGroup className="nj-pt-2 nj-pl-2 nj-mr-1">
                          <Button
                            size="small"
                            className="nj-zoom-controls-btn"
                            iconProps={{ icon: 'search-minus' }}
                            onClick={() => {
                              if (pdfScale > 1) {
                                setPdfScale(pdfScale - 0.5);
                              } else if (pdfScale > 0.25) {
                                setPdfScale(pdfScale - 0.25);
                              }
                            }}
                            disabled={pdfScale === 0.25}
                          />
                          <Dropdown overlay={zoomLister} trigger={['click']}>
                            <Button
                              size="small"
                              className="nj-zoom-controls-btn nj-pr-2"
                              iconProps={{ icon: 'search' }}
                            >
                              <>{pdfScale * 100}%</>
                            </Button>
                          </Dropdown>
                          <Button
                            size="small"
                            className="nj-zoom-controls-btn"
                            iconProps={{ icon: 'search-plus' }}
                            onClick={() => {
                              if (pdfScale < 1) {
                                setPdfScale(pdfScale + 0.25);
                              } else if (pdfScale < 5) {
                                setPdfScale(pdfScale + 0.5);
                              }
                            }}
                            disabled={pdfScale === 5}
                          />
                        </ButtonGroup>
                        <div className="nj-pt-2">
                          <Button
                            type={pdfSelect ? 'primary' : ''}
                            size="small"
                            className="nj-attachments-previewer-pdf-controls-toggle-select"
                            iconProps={{ icon: 'i-cursor' }}
                            onClick={() => setPdfSelect(!pdfSelect)}
                          />
                        </div>
                      </div>
                      <div className="nj-m-2">
                        <Document
                          onLoadSuccess={({ numPages }) =>
                            setPdfNumberOfPages(numPages)
                          }
                          file={selectedAttachment.url}
                          error={failedToLoadPdfMessage}
                        >
                          {Array.from(
                            new Array(pdfNumberOfPages),
                            (el, index) => (
                              <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={pdfScale * 1.5}
                              />
                            )
                          )}
                        </Document>
                      </div>
                    </div>
                  )) || (
                    <img
                      src={selectedAttachment.url}
                      alt={imageNotFoundMessage}
                    />
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default AttachmentsPreviewer;
