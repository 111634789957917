import React from 'react';
import { Row, Col } from 'antd';
import preloader from 'assets/images/preloader.gif';

const Preloader = () => (
  <Row
    type="flex"
    justify="space-around"
    align="middle"
    style={{ height: '100vh' }}
  >
    <Col span={24} style={{ textAlign: 'center' }}>
      <div>
        <img src={preloader} alt="Loading... " width={32} />
      </div>
    </Col>
  </Row>
);

export default Preloader;
