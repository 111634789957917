import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

import Button from '../Button';

const getBase64 = image => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result);
  });
};

const sizeValidation = (file, maxSize) => {
  const isLtMS = file.size / 1024 / 1024 < maxSize;
  if (!isLtMS) {
    message.error(`File must smaller than ${maxSize}MB!`);
  }
  return isLtMS;
};

const UploadedElement = props => {
  const { onHandleClick, info, previews, index, ...otherProps } = props;

  return (
    <div className="nj-image-previewer nj-mr-2">
      <div
        className="nj-image-previewer-preview nj-d-flex nj-justify-content-end nj-p-2"
        style={{
          backgroundImage: previews[index] ? `url(${previews[index]})` : null
        }}
      >
        <Icon icon="times-circle" onClick={onHandleClick} {...otherProps} />
      </div>
    </div>
  );
};

const MultipleImageUpload = props => {
  const {
    width,
    height,
    aligned,
    maxSize,
    value,
    onChange: onChangeHandler,
    ...otherProps
  } = props;

  const [fileList, setFileList] = useState([]);
  const [previews, setPreviews] = useState([]);
  useEffect(() => {
    if (value.length === 0) {
      setFileList([]);
    }
  }, [value]);
  const onFileChange = async ({ file }) => {
    const { originFileObj: fileObject } = file;
    const isValid = sizeValidation(file, maxSize);
    if (isValid) {
      const newFileList = fileList.concat(fileObject);
      onChangeHandler(newFileList);
      setFileList(newFileList);
      const previewImage = await getBase64(fileObject);
      const auxPreviews = previews;
      auxPreviews.push(previewImage);
      setPreviews([...auxPreviews]);
    }
  };
  const onDeleteItem = (item, index) => {
    const filteredFileList = fileList.filter(
      element => element.uid !== item.uid
    );
    setFileList(filteredFileList);
    onChangeHandler(filteredFileList);
    const aux = previews;
    aux.splice(index, 1);
    setPreviews([...aux]);
  };
  // previews

  return (
    <div className={classNames({ aligned }, 'file-uploader')}>
      <div
        className="nj-multiple-image-upload nj-d-flex"
        style={{ width, height }}
      >
        {fileList.map((element, index) => (
          <UploadedElement
            key={element.uid}
            onHandleClick={() => onDeleteItem(element, index)}
            info={element}
            index={index}
            previews={previews}
          />
        ))}
        <Upload
          multiple
          showUploadList={false}
          fileList={fileList}
          customRequest={() => {}}
          onChange={onFileChange}
          {...otherProps}
        >
          <Button
            iconProps={{ icon: 'plus' }}
            type="dashed"
            className="upload-button nj-image-previewer-upload-button"
          />
        </Upload>
      </div>
    </div>
  );
};

export default MultipleImageUpload;

MultipleImageUpload.propTypes = {
  /** Set height */
  height: PropTypes.string,

  /** Set width */
  width: PropTypes.string,

  /** Set align display */
  aligned: PropTypes.bool,

  /** Set maximum file size in MegaBytes (MB) */
  maxSize: PropTypes.number,

  /** Handles change */
  onChange: PropTypes.func
};

MultipleImageUpload.defaultProps = {
  height: '100%',
  width: '100%',
  aligned: false,
  maxSize: 2,
  onChange: /* istanbul ignore next */ () => {}
};
