import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Upload, message } from 'antd';
import Button from '@njano/Button';
import ImgCrop from 'antd-img-crop';

const getBase64 = image => {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.readAsDataURL(image);
    reader.onload = () => resolve(reader.result);
  });
};

const sizeValidation = (file, maxSize) => {
  const isLtMS = file.size / 1024 / 1024 < maxSize;
  if (!isLtMS) {
    message.error(`Image must smaller than ${maxSize}MB!`);
  }
  return isLtMS;
};

const ImageUpload = props => {
  const {
    height,
    width,
    borderRadius,
    importBtnStyle,
    trashBtnStyle,
    defaultImage,
    maxSize,
    onChange: onChangeHandler,
    cropProps,
    ...otherProps
  } = props;

  const [image, setImage] = useState(defaultImage);
  useEffect(() => {
    setImage(defaultImage);
  }, [defaultImage]);
  return (
    <div
      className="image-uploader"
      style={{
        position: 'relative',
        borderRadius,
        height,
        width,
        backgroundImage: image ? `url(${image})` : null
      }}
      {...otherProps}
    >
      <Button
        iconProps={{ icon: 'trash-alt' }}
        className="upload-button left-up-btn"
        onClick={() => setImage(null)}
        data-testid="image-upload-delete"
        style={trashBtnStyle}
      />
      {cropProps && !cropProps.disabled ? (
        <ImgCrop {...cropProps}>
          <Upload
            showUploadList={false}
            multiple={false}
            accept="image/png, image/jpeg, image/jpg"
            customRequest={() => {}}
            beforeUpload={file => sizeValidation(file, maxSize)}
            onChange={async info => {
              if (info.file.originFileObj) {
                const previewImage = await getBase64(info.file.originFileObj);
                setImage(previewImage);
              }
              onChangeHandler(info.file.originFileObj);
            }}
          >
            <Button
              iconProps={{ icon: 'image' }}
              className="upload-button right-up-btn"
              style={importBtnStyle}
            />
          </Upload>
        </ImgCrop>
      ) : (
        <Upload
          showUploadList={false}
          multiple={false}
          accept="image/png, image/jpeg, image/jpg"
          customRequest={() => {}}
          beforeUpload={file => sizeValidation(file, maxSize)}
          onChange={async info => {
            if (info.file.originFileObj) {
              const previewImage = await getBase64(info.file.originFileObj);
              setImage(previewImage);
            }
            onChangeHandler(info.file.originFileObj);
          }}
        >
          <Button
            iconProps={{ icon: 'image' }}
            className="upload-button right-up-btn"
            style={importBtnStyle}
          />
        </Upload>
      )}
    </div>
  );
};

export default ImageUpload;

ImageUpload.propTypes = {
  /** Set height */
  height: PropTypes.string,

  /** Set width */
  width: PropTypes.string,

  /** Set border radius */
  borderRadius: PropTypes.string,

  /** Set import button's style */
  importBtnStyle: PropTypes.objectOf(PropTypes.string),

  /** Set trash button's style */
  trashBtnStyle: PropTypes.objectOf(PropTypes.string),

  /** Set default (or previous) image */
  defaultImage: PropTypes.string,

  /** Set maximum file size in MegaBytes (MB) */
  maxSize: PropTypes.number,

  /** Handles change */
  onChange: PropTypes.func
};

ImageUpload.defaultProps = {
  height: '270px',
  width: '480px',
  borderRadius: undefined,
  trashBtnStyle: undefined,
  importBtnStyle: undefined,
  defaultImage: undefined,
  maxSize: 2,
  onChange: /* istanbul ignore next */ () => {}
};
