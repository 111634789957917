import React, { useState, isValidElement } from 'react';
import { DatePicker, Form, Select, Checkbox, Radio } from 'antd';
import { nanoid } from 'nanoid';
import { TwitterPicker as ColorPicker } from 'react-color';
import dayjs from 'utils/dayjs';

import Input from '../Input';
import Button from '../Button';
import TimePicker from '../TimePicker';
import ImageUpload from '../ImageUpload';
import MultipleImageUpload from '../MultipleImageUpload';
import FileUpload from '../FileUpload';
const FormItem = Form.Item;
const { RangePicker } = DatePicker;

const CreateAntField = AntComponent => ({
  field,
  form,
  hasFeedback,
  pickerText,
  selectOptions,
  submitCount,
  type,
  onChange: onChangeHandler = () => {},
  children,
  required = false,
  label: defaultLabel,
  ...props
}) => {
  /* eslint-disable react-hooks/rules-of-hooks */
  const [toggle, setToggle] = useState(false);
  const touched = form.touched[field.name];
  const submitted = submitCount > 0;
  const hasError = form.errors[field.name];
  const submittedError = hasError && submitted;
  const touchedError = hasError && touched;
  const label =
    required && defaultLabel && !isValidElement(defaultLabel)
      ? defaultLabel.concat(' *')
      : defaultLabel;
  const onInputChange = ({ target: { value } }) => {
    form.setFieldValue(field.name, value);
    onChangeHandler(value);
  };
  const onChange = value => {
    form.setFieldValue(field.name, value);
    onChangeHandler(value);
  };
  const onDateChange = date => {
    form.setFieldValue(field.name, dayjs(date).format('YYYY-MM-DD'));
    onChangeHandler(dayjs(date).format('YYYY-MM-DD'));
  };
  const onCheckboxChange = ({ target: { checked } }) => {
    form.setFieldValue(field.name, checked ? 1 : 0);
    form.setFieldTouched(field.name, true);
    onChangeHandler(checked);
  };
  const onRadioGroupChange = ({ target: { value } }) => {
    form.setFieldValue(field.name, value);
    form.setFieldTouched(field.name, true);
    onChangeHandler(value);
  };
  const onBlur = () => form.setFieldTouched(field.name, true);
  const onToggle = () => {
    setToggle(!toggle);
  };

  const customInput = () => {
    switch (type) {
      case 'checkbox':
        return (
          <AntComponent
            {...field}
            {...props}
            checked={form.values[field.name]}
            onChange={onCheckboxChange}
          >
            {children}
          </AntComponent>
        );
      case 'color':
        return (
          <>
            {label && <div className="input-label">{label}</div>}
            <Button
              backgroundColor={
                form.values[field.name] ? form.values[field.name].hex : null
              }
              width="100"
              onClick={onToggle}
            >
              {pickerText}
            </Button>
            {toggle && (
              <AntComponent
                {...field}
                {...props}
                type={type}
                color={form.values[field.name]}
                onBlur={onBlur}
                onChange={onChange}
              />
            )}
          </>
        );
      case 'datepicker':
        return (
          <>
            {label && <div className="input-label">{label}</div>}
            <AntComponent
              {...field}
              {...props}
              value={
                field.value
                  ? dayjs(field.value, 'YYYY-MM-DD')
                  : props.defaultValue
              }
              type={type}
              onBlur={onBlur}
              onChange={onDateChange}
            />
          </>
        );
      case 'radio-group':
        return (
          <>
            {label && <div className="input-label">{label}</div>}
            <AntComponent
              {...field}
              {...props}
              checked={form.values[field.name]}
              onChange={onRadioGroupChange}
            >
              {children}
            </AntComponent>
          </>
        );
      default:
        return (
          <>
            {!selectOptions && label && (
              <div className="input-label">{label}</div>
            )}
            <AntComponent
              {...field}
              {...props}
              type={type}
              onBlur={onBlur}
              onChange={type && type !== 'number' ? onInputChange : onChange}
            >
              {selectOptions &&
                selectOptions.map(opt => (
                  <Select.Option key={nanoid()} value={opt.value}>
                    {opt.label}
                  </Select.Option>
                ))}
            </AntComponent>
          </>
        );
    }
  };
  return (
    <FormItem
      hasFeedback={!!((hasFeedback && submitted) || (hasFeedback && touched))}
      help={submittedError || touchedError ? hasError : false}
      validateStatus={submittedError || touchedError ? 'error' : 'success'}
    >
      {selectOptions && label && <div className="input-label">{label}</div>}
      {customInput()}
    </FormItem>
  );
};
export const FormSelect = CreateAntField(Select);
export const FormCheckbox = CreateAntField(Checkbox);
export const FormDatePicker = CreateAntField(DatePicker);
export const FormInput = CreateAntField(Input);
export const FormTimePicker = CreateAntField(TimePicker);
export const FormRangePicker = CreateAntField(RangePicker);
export const FormImageUpload = CreateAntField(ImageUpload);
export const FormMultipleImageUpload = CreateAntField(MultipleImageUpload);
export const FormColorPicker = CreateAntField(ColorPicker);
export const FormFileUpload = CreateAntField(FileUpload);
export const FormRadioGroup = CreateAntField(Radio.Group);
