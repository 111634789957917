import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import appMessages from 'containers/App/messages';
import { selectFetch } from 'containers/App/selectors';
import { FormInput, Button } from '@njano';

import { updateCoworkingAction } from '../actions';
import messages from '../messages';

const UpdateCoworking = props => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const loading = useSelector(selectFetch);

  const { coworking, destroyModal } = props;
  return (
    <Formik
      initialValues={{
        name: coworking.name,
        fqdn: coworking.fqdn
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string().required(
          intl.formatMessage(
            {
              ...appMessages.required
            },
            {
              attribute: intl.formatMessage({
                ...messages.name
              })
            }
          )
        ),
        fqdn: Yup.string()
          .trim()
          .matches(
            /^(?:(?:\*|(?!-)(?:xn--)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1})\.)(?:(?!-)(?:xn--)?[a-zA-Z0-9][a-zA-Z0-9-_]{0,61}[a-zA-Z0-9]{0,1}\.)*(?!-)(?:xn--)?(?:[a-zA-Z0-9]{1,50}|[a-zA-Z0-9-]{1,30}\.[a-zA-Z]{2,})$/i,
            'please enter a valid url'
          )
          .required(
            intl.formatMessage(
              {
                ...appMessages.required
              },
              {
                attribute: intl.formatMessage({
                  ...messages.fqdn
                })
              }
            )
          )
      })}
      onSubmit={(values, actions) => {
        dispatch(
          updateCoworkingAction(coworking.id, values, actions, destroyModal)
        );
      }}
    >
      {() => (
        <Form className="tickets-addmodal">
          <div className="nj-finance-card-base nj-py-3 nj-px-3 nj-mb-4">
            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.fqdn} />
            </div>
            <Field
              name="fqdn"
              type="text"
              placeholder="spacename.njano.org"
              component={FormInput}
            />
            <div className="nj-text-uppercase">
              <FormattedMessage {...messages.name} />
            </div>
            <Field
              name="name"
              type="text"
              placeholder={intl.formatMessage({
                ...messages.name
              })}
              component={FormInput}
            />

            <div className="nj-flex-row nj-justify-content-end">
              <Button
                className="nj-mb-0"
                onClick={() => {
                  props.destroyModal();
                }}
              >
                <FormattedMessage {...appMessages.cancel} />
              </Button>
              <Button
                className="nj-mb-0"
                type="primary"
                htmlType="submit"
                loading={loading}
              >
                <FormattedMessage {...messages.submit} />
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateCoworking;
