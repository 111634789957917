export const FETCH_START = 'App/FETCH_START';
export const FETCH_SUCCESS = 'App/FETCH_SUCCESS';
export const FETCH_ERROR = 'App/FETCH_ERROR';
export const SHOW_MESSAGE = 'App/SHOW_MESSAGE';
export const HIDE_MESSAGE = 'App/HIDE_MESSAGE';
export const USER_REQUEST = 'App/USER_REQUEST';
export const INFO_REQUEST = 'App/INFO_REQUEST';
export const TOKEN_EXPIRED = 'App/TOKEN_EXPIRED';
export const SET_INFO = 'App/SET_INFO';
export const SET_USER = 'App/SET_USER';
export const LOGOUT_REQUEST = 'App/LOGOUT_USER';
export const SET_AUTH = 'App/SET_AUTH';
export const TRIGGER_DRAWER = 'App/TRIGGER_DRAWER';
export const TRIGGER_SIDEBAR = 'App/TRIGGER_SIDEBAR';
export const TAB_SIZE = 992;
export const CIRCLE_COLLAPSE_SIZE = 767;
export const TOKEN_REFRESH = 86400;
