import React, { useEffect, useState } from 'react';
import { hot } from 'react-hot-loader/root';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LanguageProvider, Login } from 'containers';
import { message as alertMessage, notification, Button } from 'antd';
import useShallowEqualSelector from 'utils/useShallowEqualSelector';
import useInjectSaga from 'utils/injectSaga';
import { Title } from 'components';

import * as serviceWorker from '../../serviceWorker';
import { selectError, selectToken } from './selectors';
import AppContainer from './AppContainer';
import RestrictedRoute from './RestrictedRoute';
import saga from './saga';

const App = ({ messages, match }) => {
  useInjectSaga({ key: 'App', saga });
  const token = useSelector(selectToken);
  const { message, error } = useShallowEqualSelector(selectError);
  const [showReload, setShowReload] = useState(false);
  const [waitingWorker, setWaitingWorker] = useState(null);

  useEffect(() => {
    if (error) {
      alertMessage.error(error);
    }
    if (message) {
      alertMessage.success(message);
    }
  }, [error, message]);

  const openNotification = () => {
    const btn = (
      <Button
        type="primary"
        size="small"
        onClick={() => {
          if (waitingWorker) {
            waitingWorker.postMessage({ type: 'SKIP_WAITING' });
          }
          notification.close('swUpdate');
          setShowReload(false);
          window.location.reload();
        }}
      >
        Update
      </Button>
    );
    notification.open({
      message: 'New Update available',
      description: 'There is a new version of Njano available.',
      btn,
      key: 'swUpdate'
    });
  };
  useEffect(() => {
    const onSWUpdate = registration => {
      setShowReload(true);
      setWaitingWorker(registration.waiting);
    };
    serviceWorker.register({ onUpdate: onSWUpdate });
  }, [showReload, waitingWorker]);
  return (
    <>
      {showReload && openNotification()}
      <Title />
      <LanguageProvider messages={messages}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <RestrictedRoute
            path={match.path}
            token={token}
            component={AppContainer}
          />
        </Switch>
      </LanguageProvider>
    </>
  );
};

App.propTypes = {
  messages: PropTypes.objectOf(PropTypes.object)
};
App.defaultProps = {
  messages: {}
};
export default hot(App);
