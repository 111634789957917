import React from 'react';
import { Row } from 'antd';
import Card from 'components/Card';

export const Counts = props => {
  const { usersCount, activeCount, coworkingsLength, waitngListLength } = props;
  return (
    <Row type="flex" justify="space-between">
      {usersCount !== undefined ? (
        <Card
          number={usersCount}
          text="Number of users"
          widgetName="nj-count-users-base"
        />
      ) : null}
      {activeCount !== undefined ? (
        <Card
          number={activeCount}
          text="Number of active users"
          note="In the last 30 days"
          widgetName="nj-active-users-base"
        />
      ) : null}
      <Card
        number={coworkingsLength}
        text="Number of coworking spaces"
        widgetName="nj-count-coworkings-base"
      />
      <Card
        number={waitngListLength}
        text="Waiting List"
        widgetName="nj-count-waitinglist-base"
      />
    </Row>
  );
};
