import React, { useState, useEffect, useCallback } from 'react';
import {
  Table,
  Avatar,
  Row,
  Col,
  Tabs,
  Menu,
  Dropdown,
  Modal,
  Button
} from 'antd';
import Logo from 'components/Logo';
import logo from 'assets/images/logo-placeholder.png';
import appMessages from 'containers/App/messages';
import { useIntl, FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import useInjectSaga from 'utils/injectSaga';
import useInjectReducer from 'utils/injectReducer';
import { SearchBar } from '@njano';

import saga from './saga';
import reducer from './reducer';
import {
  selectCoworkings,
  selectWaitingList,
  selectReportsList
} from './selectors';
import messages from './messages';
import {
  coworkingDelete,
  coworkingsFetch,
  fetchWaitingList,
  reportsFetchRequest
} from './actions';
import CreateCoworking from './Create';
import UpdateCoworking from './Update';
import { logoutRequest } from '../App/actions';
import { Counts } from './Counts';
const { confirm } = Modal;
const Dashboard = () => {
  const dispatch = useDispatch();
  useInjectSaga({ key: 'coworking', saga });
  useInjectReducer({ key: 'coworking', reducer });
  const [search, setSearch] = useState('');
  const intl = useIntl();
  const { TabPane } = Tabs;
  const { coworkings, activeCount, usersCount } = useSelector(selectCoworkings);
  const waitngList = useSelector(selectWaitingList);
  const reportsList = useSelector(selectReportsList);
  const [showEditModal, setShowEditModal] = useState(false);
  const [coworking, setCoworking] = useState(null);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const onSearch = useCallback(
    () =>
      coworkings.filter(
        ({ name }) =>
          name.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
      ),
    [coworkings, search]
  );
  const coworSearch = onSearch();
  const onSearchReports = useCallback(
    () =>
      reportsList.filter(
        ({ description }) =>
          description.toLowerCase().indexOf(search.trim().toLowerCase()) !== -1
      ),
    [reportsList, search]
  );
  const reports = onSearchReports();
  const actionsMenu = record => (
    <Menu
      onClick={({ key }) => {
        if (key === 'change') {
          setShowEditModal(true);
          setCoworking(record);
        }
        if (key === 'delete') {
          confirm({
            title: intl.formatMessage(
              { ...appMessages.deleteConfirmTitle },
              { attribute: 'Workspace' }
            ),
            okText: intl.formatMessage({ ...appMessages.delete }),
            okType: 'danger',
            cancelText: intl.formatMessage({ ...appMessages.cancel }),
            async onOk() {
              dispatch(coworkingDelete(record.id));
            },
            onCancel() {}
          });
        }
      }}
    >
      <Menu.Item key="change">
        <FormattedMessage {...messages.editWorkspace} />
      </Menu.Item>
      <Menu.Item key="delete">
        <FormattedMessage {...messages.deleteWorkspace} />
      </Menu.Item>
    </Menu>
  );
  useEffect(() => {
    dispatch(coworkingsFetch());
    dispatch(fetchWaitingList());
    dispatch(reportsFetchRequest());
  }, [dispatch]);
  const columns = [
    {
      title: 'LOGO',
      dataIndex: 'logo',
      key: 'logo',
      align: 'center',
      render: picture => (
        <Avatar size={50} shape="square" src={picture || logo} />
      )
    },
    {
      title: 'Coworking Space',
      dataIndex: 'name',
      key: 'name',
      align: 'center',
      sorter: (a, b) => a?.name?.localeCompare(b?.name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Njano Domain',
      dataIndex: 'fqdn',
      key: 'fqdn',
      align: 'center',
      sorter: (a, b) => a?.fqdn?.localeCompare(b?.fqdn),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      align: 'center',
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortDirections: ['descend', 'ascend'],
      render: text => <span className="nj-text-capitalize">{text}</span>
    },
    {
      title: intl.formatMessage(appMessages.actions),
      key: 'actions',
      align: 'center',
      render: (text, record) => (
        <>
          <Dropdown overlay={actionsMenu(record)}>
            <Button>...</Button>
          </Dropdown>
        </>
      )
    }
  ];
  const waitingListColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      align: 'center',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      align: 'center',
      sorter: (a, b) => a?.date?.localeCompare(b?.date),
      sortDirections: ['descend', 'ascend']
    }
  ];
  const reportsColumns = [
    {
      title: intl.formatMessage(messages.type),
      key: 'type',
      align: 'center',
      render: (text, record) => (
        <div className="nj-d-block nj-mx-auto">
          <span className="nj-ml-10" style={{ userSelect: 'none' }}>
            {record.content_type.substring(
              record.content_type.lastIndexOf('\\') + 1
            )}
          </span>
        </div>
      ),
      sorter: (a, b) => a?.type?.localeCompare(b?.type),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.category),
      key: 'category',
      align: 'center',
      render: (text, record) => (
        <div className="nj-d-block nj-mx-auto">
          <span className="nj-ml-10" style={{ userSelect: 'none' }}>
            {record.category}
          </span>
        </div>
      ),
      sorter: (a, b) => a?.category?.localeCompare(b?.category),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.name),
      key: 'name',
      align: 'center',
      render: (text, record) => (
        <div className="nj-d-block nj-mx-auto">
          <span className="nj-ml-10" style={{ userSelect: 'none' }}>
            {record.coworking_id ? record.content.coworking_name : '-'}
          </span>
        </div>
      ),
      sorter: (a, b) =>
        a.content &&
        b.content &&
        a?.content.coworking_name?.localeCompare(b?.content.coworking_name),
      sortDirections: ['descend', 'ascend']
    },
    {
      title: intl.formatMessage(messages.description),
      key: 'description',
      align: 'center',
      render: (text, record) => (
        <div className="nj-d-block nj-mx-auto">
          <span className="nj-ml-10" style={{ userSelect: 'none' }}>
            {record.description}
          </span>
        </div>
      ),
      sorter: (a, b) => a?.description?.localeCompare(b?.description),
      sortDirections: ['descend', 'ascend']
    }
  ];
  return (
    <div className="nj-container">
      <Row type="flex" justify="center">
        <Col>
          <Logo />
        </Col>
      </Row>
      <Row type="flex" justify="center">
        <Col xs={18}>
          <Counts
            usersCount={usersCount}
            activeCount={activeCount}
            coworkingsLength={coworkings.length}
            waitngListLength={waitngList ? waitngList.length : 0}
          />
          <div className="nj-mx-3">
            <SearchBar
              className="nj-my-3"
              style={{ height: '50px' }}
              value={search}
              onChange={({ target: { value } }) => setSearch(value)}
            />
            <Row>
              <Button
                className="nj-mb-0"
                type="primary"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                Add a new Njano space
              </Button>
              <Button
                className="nj-mb-0"
                type="danger"
                onClick={() => {
                  dispatch(logoutRequest());
                }}
              >
                Logout
              </Button>
            </Row>
            {coworkings.length !== 0 ? (
              <Tabs defaultActiveKey="1">
                <TabPane tab="Space info" key="1">
                  <Table
                    dataSource={coworSearch.length > 0 ? coworSearch : []}
                    columns={columns}
                    rowKey="id"
                    pagination={coworkings.length > 10}
                  />
                </TabPane>
                <TabPane tab="Waiting List" key="2">
                  <Table
                    dataSource={waitngList}
                    columns={waitingListColumns}
                    rowKey="id"
                    pagination={waitngList.length > 10}
                  />
                </TabPane>
                <TabPane tab="Reports" key="3">
                  <Table
                    dataSource={reports}
                    columns={reportsColumns}
                    rowKey="id"
                    pagination={reports.length > 10}
                  />
                </TabPane>
              </Tabs>
            ) : null}
            <Modal
              className="container-modal"
              title=""
              width={600}
              visible={showCreateModal}
              closable={false}
              destroyOnClose
              footer={null}
              onOk={() => {}}
              onCancel={() => {
                setShowCreateModal(!showCreateModal);
              }}
            >
              <CreateCoworking
                destroyModal={() => [setShowCreateModal(false)]}
              />
            </Modal>
            <Modal
              className="container-modal"
              title=""
              width={600}
              visible={showEditModal}
              closable={false}
              destroyOnClose
              footer={null}
              onOk={() => {}}
              onCancel={() => {
                setShowEditModal(!showEditModal);
              }}
            >
              <UpdateCoworking
                destroyModal={() => [setShowEditModal(false)]}
                coworking={coworking}
              />
            </Modal>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default Dashboard;
