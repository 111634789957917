import React from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Icon } from 'antd';
import dayjs from 'utils/dayjs';

const disabledTimeRange = (
  _,
  type,
  disabledHoursStart,
  disabledMinutesStart,
  disabledHoursEnd,
  disabledMinutesEnd
) => {
  if (type === 'start') {
    return {
      disabledHours: () => disabledHoursStart,
      disabledMinutes: () => disabledMinutesStart
    };
  }
  return {
    disabledHours: () => disabledHoursEnd,
    disabledMinutes: () => disabledMinutesEnd
  };
};

const TimePicker = props => {
  const {
    disabledHoursStart,
    disabledMinutesStart,
    disabledHoursEnd,
    disabledMinutesEnd
  } = props;
  return (
    <DatePicker.RangePicker
      disabledTime={(_, type) =>
        disabledTimeRange(
          _,
          type,
          disabledHoursStart,
          disabledMinutesStart,
          disabledHoursEnd,
          disabledMinutesEnd
        )
      }
      className="time-picker"
      {...props}
    />
  );
};

TimePicker.propTypes = {
  /** mode of TimePicker */
  mode: PropTypes.arrayOf(PropTypes.string),
  /** deafaultValue of TimePicker */
  defaultValue: PropTypes.arrayOf(PropTypes.instanceOf(dayjs)),
  /** defaultPickerValue of TimePicker */
  defaultPickerValue: PropTypes.instanceOf(dayjs),
  /** showTime state of TimePicker */
  showTime: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.func,
      PropTypes.instanceOf(dayjs)
    ])
  ),
  /** placeholder of TimePicker */
  placeholder: PropTypes.arrayOf(PropTypes.string),
  /** output format of TimePicker */
  format: PropTypes.string,
  /** output format of TimePicker */
  ranges: PropTypes.objectOf(PropTypes.instanceOf(dayjs)),

  /** separator of TimePicker */
  separator: PropTypes.string,
  /** suffixIcon of TimePicker */
  suffixIcon: PropTypes.element,

  /** disabled state of TimePicker */
  disabled: PropTypes.bool,
  /** hours Start disabled Time of TimePicker */
  disabledHoursStart: PropTypes.arrayOf(PropTypes.number),
  /** minutes Start disabled Time of TimePicker */
  disabledMinutesStart: PropTypes.arrayOf(PropTypes.number),
  /** hours End disabled Time of TimePicker */
  disabledHoursEnd: PropTypes.arrayOf(PropTypes.number),
  /** minutes End disabled Time of TimePicker */
  disabledMinutesEnd: PropTypes.arrayOf(PropTypes.number),
  /** set the style of button */
  style: PropTypes.objectOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
      PropTypes.element,
      PropTypes.func
    ])
  ),
  /** set the handler to handle click event */
  renderExtraFooter: PropTypes.func,
  /** set the handler to handle click event */
  onOk: PropTypes.func,
  /** set the handler to handle click event */
  onChange: PropTypes.func,
  /** set the handler to handle click event */
  onCalendarChange: PropTypes.func
};

TimePicker.defaultProps = {
  mode: ['time', 'time'],
  format: 'HH:mm',
  defaultPickerValue: dayjs('00:00', 'HH:mm'),
  showTime: {
    defaultValue: dayjs('00:00', 'dddd HH:mm'),
    format: 'dddd HH:mm'
  },
  placeholder: ['Start Time', 'End Time'],
  separator: '|',
  suffixIcon: <Icon type="clock-circle" />,
  defaultValue: undefined,
  disabled: false,
  disabledHoursStart: [],
  disabledMinutesStart: [],
  disabledHoursEnd: [],
  disabledMinutesEnd: [],
  style: undefined,
  ranges: undefined,
  renderExtraFooter: /* istanbul ignore next */ () => {},
  onOk: /* istanbul ignore next */ () => {},
  onChange: /* istanbul ignore next */ () => {},
  onCalendarChange: /* istanbul ignore next */ () => {}
};
export default TimePicker;
