import React from 'react';
import { Route, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'containers/App/selectors';
import { FormattedMessage } from 'react-intl';
import image from 'assets/images/social.png';
import { Button } from '@njano';

import messages from './messages';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const { has_profile: hasSocialProfile } = useSelector(selectCurrentUser);

  return (
    <Route
      {...rest}
      render={props =>
        hasSocialProfile ? (
          <Component {...props} />
        ) : (
          <div className="nj-flex-column nj-align-items-center nj-text-center">
            <img src={image} alt="nope" width="250" />
            <div className="nj-mt-3 nj-no-social-profile-text-container">
              <h1>
                <FormattedMessage {...messages.messageText} />
              </h1>
            </div>
            <Button
              type="primary"
              onClick={() => {
                history.push('/community-account');
              }}
            >
              <FormattedMessage {...messages.activateProfile} />
            </Button>
          </div>
        )
      }
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  /** role */
  is: PropTypes.string,

  /** children displayed if is matches with role */
  component: PropTypes.elementType
};
PrivateRoute.defaultProps = {
  is: '',
  component: null
};
