import React from 'react';
import classNames from 'classnames';
import RSC from 'react-scrollbars-custom';

const ScrollBar = React.forwardRef((props, ref) => {
  const { className, horizontal, ...otherProps } = props;
  if (horizontal) {
    return (
      <RSC
        {...otherProps}
        ref={ref}
        trackXProps={{
          renderer: moreprops => {
            const { elementRef, style, ...restProps } = moreprops;
            return (
              <span
                {...restProps}
                style={{ ...style, height: '3px', borderRadius: 'O' }}
                ref={elementRef}
              />
            );
          }
        }}
        className={classNames('nj-horizontal-scrollbar', className)}
      >
        {props.children}
      </RSC>
    );
  }

  return (
    <RSC
      {...otherProps}
      ref={ref}
      trackYProps={{
        renderer: moreprops => {
          const { elementRef, style, ...restProps } = moreprops;
          return (
            <span
              {...restProps}
              style={{ ...style, width: '3px', borderRadius: 'O' }}
              ref={elementRef}
            />
          );
        }
      }}
      wrapperProps={{
        renderer: moreprops => {
          const { elementRef, style, ...restProps } = moreprops;
          return (
            <span
              {...restProps}
              style={{ ...style, right: '3px' }}
              ref={elementRef}
            />
          );
        }
      }}
      className={classNames('nj-vertical-scrollbar', className)}
    >
      {props.children}
    </RSC>
  );
});

export default ScrollBar;
