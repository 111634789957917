/* eslint-disable no-prototype-builtins */
import { persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';
import globalReducer from 'containers/App/reducer';
import languageProviderReducer from 'containers/LanguageProvider/reducer';

const createReducer = (injectedReducers = {}) => {
  const whitelist = [
    'global',
    'language',
    'router',
    ...(injectedReducers.hasOwnProperty('invoices') ? ['invoices'] : []),
    ...(injectedReducers.hasOwnProperty('estimates') ? ['estimates'] : [])
  ];
  const rootReducer = persistCombineReducers(
    {
      key: 'root',
      storage,
      whitelist,
      stateReconciler: state => {
        const filteredState = {};
        Object.keys(state).forEach(key => {
          if (whitelist.includes(key)) {
            filteredState[key] = state[key];
          }
        });
        return hardSet(filteredState);
      }
    },
    {
      global: globalReducer,
      language: languageProviderReducer,
      ...injectedReducers
    }
  );
  return rootReducer;
};
export default createReducer;
