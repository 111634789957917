/**
 * Parses Laravel errors object and return the first error
 *
 * @param {array} errors Errors array from response
 *
 * @return {array}       Flatten error Object
 */
export const errorFormatter = errorList => {
  const formattedErrors = [];
  Object.keys(errorList).forEach(key => {
    /* eslint-disable prefer-destructuring,no-param-reassign */
    formattedErrors[key] = errorList[key][0];
  });
  return formattedErrors;
};

const recursiveObjectAppend = (data, form, namespace = '') => {
  /* eslint-disable no-restricted-syntax */
  for (const key in data) {
    if (Object.prototype.hasOwnProperty.call(data, key)) {
      const formKey = namespace ? `${namespace}[${key}]` : key;
      if (typeof data[key] === 'object' && !(data[key] instanceof File)) {
        recursiveObjectAppend(data[key], form, formKey);
      } else {
        form.append(formKey, data[key]);
      }
    }
  }
};
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
const parseJSON = response => {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
};

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
const checkStatus = response => {
  if (
    (response.status >= 200 && response.status < 300) ||
    response.status === 422 ||
    response.status === 404
  ) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  error.status = response.status;
  throw error;
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export default (
  url,
  body,
  method = 'GET',
  token = null,
  type = 'json',
  { headers = {}, ...options } = {}
) => {
  // Append backend url if url is relative
  const { REACT_APP_BACKEND_URL } = process.env;
  const apiURL = url.startsWith('/')
    ? `${REACT_APP_BACKEND_URL}/api${url}`
    : url;
  const formData = new FormData();
  const headerList = { ...headers };
  if (type === 'form' && body) {
    recursiveObjectAppend(body, formData);
  } else {
    headerList['content-type'] = 'application/json';
  }
  if (token) {
    headerList.Authorization = `Bearer ${token}`;
  }
  const opt = {
    headers: headerList,
    method,
    credentials: 'include',
    mode: 'cors',
    ...options
  };
  if (body) {
    opt.body = type === 'form' ? formData : JSON.stringify(body);
  }
  return fetch(apiURL, opt).then(checkStatus).then(parseJSON);
};
