import {
  call,
  put,
  takeLeading,
  all,
  select,
  takeLatest
} from 'redux-saga/effects';
import request from 'utils/request';
import dayjs from 'utils/dayjs';
import { changeLocale } from 'containers/LanguageProvider/actions';
import { localeSelector } from 'containers/LanguageProvider/selectors';
import { CHANGE_LOCALE } from 'containers/LanguageProvider/constants';

import { selectToken, selectExpiry } from './selectors';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  setInfo,
  setUser,
  tokenExpired,
  setAuth,
  unsetUser
} from './actions';
import {
  USER_REQUEST,
  TOKEN_EXPIRED,
  TOKEN_REFRESH,
  LOGOUT_REQUEST,
  INFO_REQUEST
} from './constants';

function* userReset() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const locale = yield select(localeSelector);
    const expiresAt = yield select(selectExpiry);
    if (expiresAt - dayjs().unix() < TOKEN_REFRESH) {
      yield put(tokenExpired());
    }
    const response = yield call(request, '/auth/me', null, 'GET', token);

    if (response.status === 'success') {
      yield put(setUser(response.data));
      if (response.data.language && response.data.language !== locale) {
        yield put(changeLocale(response.data.language));
      }
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

function* infoReset() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(request, '/info', null, 'GET', token);
    if (response.status === 'success') {
      yield put(setInfo(response.data));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    yield put(fetchError());
    return false;
  }
}

function* refreshToken() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(request, '/auth/refresh', null, 'POST', token);
    if (response.status === 'success') {
      yield put(
        setAuth({ token: response.token, expiresIn: response.expires_in })
      );
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

function* userLocaleRequest({ locale }) {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(
      request,
      '/members',
      { language: locale, _method: 'PUT' },
      'POST',
      token
    );
    if (response.status === 'success') {
      yield put(setUser(response.data));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
      return false;
    }
    yield put(fetchError(error.message));
    return false;
  }
}

function* userLogout() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    yield call(request, '/auth/logout', { token }, 'POST');
    return true;
  } catch (error) {
    yield put(fetchError());
    return false;
  } finally {
    yield put(unsetUser());
  }
}

export default function* rootSaga() {
  yield all([
    takeLeading(USER_REQUEST, userReset),
    takeLeading(INFO_REQUEST, infoReset),
    takeLeading(TOKEN_EXPIRED, refreshToken),
    takeLeading(LOGOUT_REQUEST, userLogout),
    takeLatest(CHANGE_LOCALE, userLocaleRequest)
  ]);
}
