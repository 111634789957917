import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import messages from 'containers/App/messages';

const ReadMore = ({ charLimit, children }) => {
  const [expand, setExpand] = useState(false);

  if (children.length < charLimit) {
    return <span className="short-text">{children}</span>;
  }
  return (
    <span className="short-text">
      {expand ? children : `${children.substr(0, charLimit)}...`}
      <span
        className="nj-readmore-text nj-link"
        role="presentation"
        onClick={() => {
          setExpand(!expand);
        }}
      >
        {expand ? (
          <FormattedMessage {...messages.readLess} />
        ) : (
          <FormattedMessage {...messages.readMore} />
        )}
      </span>
    </span>
  );
};

ReadMore.propTypes = {
  charLimit: PropTypes.number,
  children: PropTypes.string.isRequired
};
ReadMore.defaultProps = {
  charLimit: 100
};

export default ReadMore;
