import React from 'react';
import PropTypes from 'prop-types';
import { Col } from 'antd';
import CountUp from 'react-countup';
import classNames from 'classnames';

const Card = ({ number, text, widgetName, note }) => {
  return (
    <Col xs={24} sm={11} md={11} lg={11}>
      <div
        className={classNames(
          'nj-widget-base nj-add-widget-min-height nj-justify-content-between nj-widget-flex',
          widgetName
        )}
      >
        <div className="nj-widget-text text-center">{text}</div>
        <div className="nj-widget-number text-center">
          <CountUp start={0} end={number} duration={2} />
        </div>
        <div className="nj-widget-text text-center">{note}</div>
      </div>
    </Col>
  );
};

export default Card;

Card.propTypes = {
  number: PropTypes.number,
  text: PropTypes.string,
  widgetName: PropTypes.string,
  note: PropTypes.string
};
Card.defaultProps = {
  number: 0,
  text: '',
  widgetName: '',
  note: ''
};
