import React, { useState } from 'react';
import { Input as AntInput, Tooltip, Icon, Popover } from 'antd';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';

const suffixIcon = () => {
  return (
    <FontAwesomeIcon icon="sliders-h" style={{ color: 'rgba(0,0,0,.45)' }} />
  );
};
const prefixIcon = () => {
  return <FontAwesomeIcon icon="search" style={{ color: 'rgba(0,0,0,.25)' }} />;
};

const SearchBar = props => {
  const [val, setVal] = useState('');
  const {
    general,
    iconTitle,
    filterClick,
    disabled,
    component,
    ...otherProps
  } = props;
  const SearchBarclass = classNames({
    'search-bar general-search-bar': general,
    'search-bar card-search-bar': !general
  });
  return (
    <AntInput
      className={SearchBarclass}
      disabled={disabled}
      value={val}
      onChange={e => {
        setVal(e.target.value);
      }}
      suffix={
        filterClick &&
        !disabled && (
          <Tooltip placement="top" title={iconTitle}>
            <Popover
              overlayClassName="nj-popover-horizantal"
              placement="bottomRight"
              content={component}
              trigger="click"
            >
              <Icon onClick={filterClick} component={suffixIcon} />
            </Popover>
          </Tooltip>
        )
      }
      {...otherProps}
    />
  );
};

SearchBar.propTypes = {
  /** type general of the SearchBar */
  general: PropTypes.bool,
  /** placeholder of the SearchBar */
  placeholder: PropTypes.string,
  /** Title of the suffix icon */
  iconTitle: PropTypes.string,
  /** add suffix text/component to the searchbar */
  addonAfter: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** add prefix text/componenet to the searchbar */
  addonBefore: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** default value of the searchbar */
  defaultValue: PropTypes.string,
  /** disable the searchbar */
  disabled: PropTypes.bool,
  /** id of the searchbar */
  id: PropTypes.string,
  /** prefix icon of the searchbar */
  prefix: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  /** size of the searchbar */
  size: PropTypes.string,
  /** type of the searchbar */
  type: PropTypes.string,
  /** allow Clear buuton in the searchbar */
  allowClear: PropTypes.bool,
  /** component of the popover */
  component: PropTypes.element,
  /** onPressEnter function of the suffix icon */
  onPressEnter: PropTypes.func,
  /** onChange function of the suffix icon */
  filterClick: PropTypes.func
};
SearchBar.defaultProps = {
  general: false,
  placeholder: 'Search...',
  iconTitle: 'Filter',
  addonAfter: undefined,
  addonBefore: undefined,
  defaultValue: undefined,
  disabled: false,
  id: undefined,
  prefix: <Icon component={prefixIcon} />,
  size: 'default',
  type: 'text',
  component: undefined,
  allowClear: true,
  onPressEnter: undefined,
  filterClick: undefined
};

export default SearchBar;
