/* eslint-disable no-unused-vars */
import React, { cloneElement, Children } from 'react';
import useSelector from 'utils/useShallowEqualSelector';
import PropTypes from 'prop-types';
import { selectUserPermission } from 'containers/App/selectors';

const isArray = object => {
  return !!object && object.constructor === Array;
};
const Role = ({ is, action, children, defaultValue, ...props }) => {
  const { role, permissions } = useSelector(selectUserPermission);
  // Check if an action is required before searching in user permissions
  const hasPermission =
    !action ||
    (permissions &&
      permissions.find(({ name, can }) => name === action && can));

  const hasRole = !is || (isArray(is) ? is.includes(role) : is === role);

  if (hasRole && hasPermission) {
    return children
      ? Children.map(children, item => cloneElement(item, props))
      : defaultValue;
  }
  return defaultValue;
};

export default Role;

Role.propTypes = {
  /** permission name */
  is: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string
  ]),

  /** action  */
  action: PropTypes.string,

  /** optional children displayed in the permission is false */
  defaultValue: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]),

  /** children displayed if is matches with role */
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};
Role.defaultProps = {
  is: '',
  children: null,
  defaultValue: null,
  action: ''
};
