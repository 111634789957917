import { call, put, takeLatest, all, select } from 'redux-saga/effects';
import request, { errorFormatter } from 'utils/request';
import cmsRequest from 'utils/cmsRequest';
import {
  fetchError,
  fetchStart,
  fetchSuccess,
  unsetUser,
  showMessage
} from 'containers/App/actions';
import { selectToken } from 'containers/App//selectors';

import {
  storeCoworkings,
  coworkingsFetch,
  StoreWaitngList,
  setReports
} from './actions';
import {
  COWORKING_DELETE,
  COWORKING_CREATE,
  COWORKING_UPDATE,
  COWORKINGS_FETCH,
  WAITINGLIST_FETCH,
  REPORTS_FETCH
} from './constants';

const { REACT_APP_WAITLIST_KEY, REACT_APP_WAITLIST_ID } = process.env;

function* fetchWaitingList() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(
      cmsRequest,
      `/wp-json/wp-njano/v1/waitlist?access_key=${REACT_APP_WAITLIST_KEY}&form_id=${REACT_APP_WAITLIST_ID}`,
      null,
      'GET',
      token
    );

    if (response.success === true) {
      yield put(StoreWaitngList(response.data));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

function* fetchCoworkings() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(
      request,
      `/coworkings/dashboard`,
      null,
      'GET',
      token
    );
    if (response.status === 'success') {
      yield put(storeCoworkings(response.data));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

function* deleteCoworking({ id }) {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(
      request,
      `/coworkings/${id}`,
      { _method: 'DELETE' },
      'POST',
      token
    );
    if (response.status === 'success') {
      yield put(showMessage(response.message));
      yield put(fetchSuccess());
      yield put(coworkingsFetch());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

function* submitCreateCoworking({
  payload: values,
  destroyModal,
  actions: { setErrors }
}) {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(request, '/coworkings', values, 'POST', token);
    if (response.status === 'success') {
      yield call(destroyModal);
      yield put(showMessage('Space created with success'));
      yield put(fetchSuccess());
      yield put(coworkingsFetch());
    } else {
      yield put(fetchError(response.message));
      if (response.errors) {
        setErrors(errorFormatter(response.errors));
      }
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}
function* submitUpdateCoworking({
  payload: values,
  destroyModal,
  actions: { setErrors },
  id
}) {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(
      request,
      `/coworkings/${id}`,
      values,
      'PUT',
      token
    );
    if (response.status === 'success') {
      yield call(destroyModal);
      yield put(showMessage(response.message));
      yield put(fetchSuccess());
      yield put(coworkingsFetch());
    } else {
      yield put(fetchError(response.message));
      if (response.errors) {
        setErrors(errorFormatter(response.errors));
      }
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}
function* reportsFetch() {
  yield put(fetchStart());
  try {
    const token = yield select(selectToken);
    const response = yield call(request, '/abuse', null, 'GET', token);
    if (response.status === 'success') {
      yield put(setReports(response.data));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.message));
    }
    return response;
  } catch (error) {
    if (error.status === 401) {
      yield put(unsetUser());
    }
    yield put(fetchError());
    return false;
  }
}

export default function* rootSaga() {
  yield all([
    takeLatest(COWORKING_DELETE, deleteCoworking),
    takeLatest(COWORKING_CREATE, submitCreateCoworking),
    takeLatest(COWORKING_UPDATE, submitUpdateCoworking),
    takeLatest(COWORKINGS_FETCH, fetchCoworkings),
    takeLatest(WAITINGLIST_FETCH, fetchWaitingList),
    takeLatest(REPORTS_FETCH, reportsFetch)
  ]);
}
