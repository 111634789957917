import produce from 'immer';

import { COWORKINGS_STORE, WAITINGLIST_STORE, REPORTS_SET } from './constants';

export const initialState = {
  coworkings: [],
  activeCount: 0,
  usersCount: 0,
  waitngList: [],
  reportsList: []
};

/* eslint-disable default-case, no-param-reassign, consistent-return */
export default (state = initialState, { type, payload }) => {
  return produce(state, draft => {
    switch (type) {
      case COWORKINGS_STORE:
        draft.activeCount = payload.active_members_count;
        draft.usersCount = payload.users_count;
        draft.coworkings = payload.coworkings;
        break;
      case WAITINGLIST_STORE:
        draft.waitngList = payload;
        break;
      case REPORTS_SET:
        draft.reportsList = payload;
        break;
      default:
        return state;
    }
  });
};
