import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Page403 } from 'containers';
import { useSelector } from 'react-redux';
import { selectUserPermission } from 'containers/App/selectors';

const PrivateRoute = ({ component: Component, is, ...rest }) => {
  const { role } = useSelector(selectUserPermission);

  return (
    <Route
      {...rest}
      render={props => (role === is ? <Component {...props} /> : <Page403 />)}
    />
  );
};

export default PrivateRoute;

PrivateRoute.propTypes = {
  /** role */
  is: PropTypes.string,

  /** children displayed if is matches with role */
  component: PropTypes.elementType
};
PrivateRoute.defaultProps = {
  is: '',
  component: null
};
