import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as AntAvatar } from 'antd';
import randomColor from 'randomcolor';

const Avatar = ({ alt, icon, size, src, username, className }) => (
  <AntAvatar
    className={className || 'user-info-avatar'}
    src={src}
    size={size}
    shape="circle"
    alt={alt}
    icon={icon}
    style={{
      backgroundColor: src ? '#ffffff' : randomColor({ seed: username }),
      verticalAlign: 'middle'
    }}
  >
    {username.slice(0, 1)}
  </AntAvatar>
);

Avatar.propTypes = {
  /** Alternative text for image */
  alt: PropTypes.string,
  /** Image replacement as icon name */
  icon: PropTypes.string,
  /** size in pixels, default 64 */
  size: PropTypes.number,
  /** Image source url */
  src: PropTypes.string,
  /** Username for generating Avatar from First letter */
  username: PropTypes.string
};

Avatar.defaultProps = {
  alt: 'User Avatar',
  icon: null,
  size: 64,
  src: null,
  username: 'U'
};

export default Avatar;
